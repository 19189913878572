import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useQueryClient } from '@tanstack/react-query'
import { useGetLessonByIdQuery } from '~/queries/autogenerate/hooks'

import { useAuth } from '~/_context/Auth'

import { graphqlRequestClient } from '~/server/GraphQLClient'

import {
  useProjectServiceAssessment,
  useProjectServiceAssessmentBatch,
  useServiceAssessment,
  useServiceAssessmentBatch,
} from '~/services/Assessment'
import { useServiceGetLesson } from '~/services/Lesson'
import { useGetProjectInformation } from '~/services/Project'
import { useGetStudentsGroupProject } from '~/services/Students'

import { defaultState } from './default.states'
import { IAssessmentPageContextProps, IAssessmentPageProviderProps } from './interfaces'

const AssessmentPageContext = createContext<IAssessmentPageContextProps>({
  tabActived: 0,
  handleActiveNewTab: () => Object,
  data: defaultState,
  mutateAssessment: () => Object,
  mutateAssessmentBatch: () => Object,
  isLoadingAssessment: false,
  isSameUser: false,
  stage: [],
  idActivity: 0,
  fixedPayload: [],
  variablePayload: [],
  firstEvidence: 0,
  lessonStep: [],
  status: '',
  projectId: 0,
  students: [],
  isLoading: true,
  mutateAssessmentProject: () => Object,
  mutateAssessmentBatchProject: () => Object,
  isLoadingAssessmentProject: false,
  isLoadingAssessmentBatch: false,
  isLoadingAssessmentBatchProject: false,
  isProject: false,
  idLessonMoment: 0,
  idClass: 0,
  isClassInStatus: true,
})

const AssessmentPageProvider = ({ children }: IAssessmentPageProviderProps) => {
  const { idLessonMoment, idLesson, projectId, idClass } = useParams()
  const queryClient = useQueryClient()
  const isProject = projectId ? true : false
  const { user } = useAuth()
  const [tabActived, setTabActived] = useState<0 | 1 | 2 | number>(0)

  const { data, remove: removeLesson } = useGetLessonByIdQuery(
    graphqlRequestClient('moment'),
    {
      lessonId: Number(idLessonMoment),
    },
    { enabled: !!idLessonMoment },
  )

  const { data: dataProject, remove: removeProject } = useGetProjectInformation(Number(projectId), {
    enabled: isProject,
  })

  /*   useEffect(() => {
    removeProject()
    removeLesson()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) */

  // Request Lesson

  const {
    data: students,
    isLoading,
    refetch: refetchStudents,
  } = useGetStudentsGroupProject(Number(projectId), {
    enabled: !!projectId,
  })

  const { data: dataProfessor } = useGetProjectInformation(Number(projectId), { enabled: !!projectId })
  const { data: dataLessonInformation } = useServiceGetLesson(Number(idLessonMoment), { enabled: !!idLessonMoment })
  const idUserProfessor = dataProfessor?.idUserProfessor
  const idUserProfessorLesson = dataLessonInformation?.idUserProfessor
  const { isLoading: isLoadingAssessment, mutate: mutateAssessment } = useServiceAssessment()
  const { mutate: mutateAssessmentBatch, isLoading: isLoadingAssessmentBatch } = useServiceAssessmentBatch({
    onSuccess: () => {
      queryClient.invalidateQueries(['getIdenticalLessonMoments'])
    },
  })

  const getLessonStep = useCallback(() => {
    return (
      data?.lessonMomentById?.[0]?.lessonTrackGroup?.lessonTrack?.grade?.lesson?.find(
        (item) => item?.idLesson === Number(idLesson),
      )?.lessonStep || []
    )
  }, [data, idLesson])

  const firstEvidence =
    data?.lessonMomentById?.[0]?.lessonTrackGroup?.lessonTrack?.grade?.lesson?.[0]?.lessonStep?.[0]?.lessonActivity?.[0]
      ?.lessonActivityEvidence?.[0]?.evidenceGrade?.idEvidence

  // Request Project

  const isClassInStatus = dataProject?.class?.inStatus || dataLessonInformation?.class?.inStatus

  const { isLoading: isLoadingAssessmentProject, mutate: mutateAssessmentProject } = useProjectServiceAssessment()
  const { mutate: mutateAssessmentBatchProject, isLoading: isLoadingAssessmentBatchProject } =
    useProjectServiceAssessmentBatch({
      onSuccess: () => {
        queryClient.invalidateQueries(['getIdenticalLessonMoments'])
      },
    })

  const getStages = useCallback(() => {
    return dataProject?.projectMomentStage || []
  }, [dataProject])

  const idActivity = dataProject?.projectMomentStage?.[tabActived]?.idProjectMomentStage
  const fixedPayload = dataProject?.projectMomentStage[tabActived]?.projectStage?.evidenceFixed
  const variablePayload = dataProject?.projectMomentStage[tabActived]?.projectStage?.evidenceVariable
  const isSameUser = user?.id_user === idUserProfessorLesson || user?.id_user === idUserProfessor

  const status = isProject
    ? dataProject?.momentStatus?.coMomentStatus
    : data?.lessonMomentById?.[0]?.momentStatus?.coMomentStatus

  const classTitle = dataProject
    ? dataProject.projectTrackStage?.txTitle
    : data?.lessonMomentById[0]?.lessonTrackGroup?.lessonTrack?.grade?.lesson[0]?.txTitle || 'Voltar'

  const path = dataProject
    ? `/class/${idClass}/project/${projectId}`
    : data
    ? `/class/${idClass}/lesson/${idLessonMoment}`
    : '/'

  const handleActiveNewTab = useCallback((newValue: 0 | 1 | 2 | number) => {
    setTabActived(newValue)
  }, [])

  useEffect(() => {
    setTabActived(0)
  }, [isLoading])

  const lessonStep = getLessonStep()
  const stage = getStages()

  const AssessmentPageProviderValues = useMemo(() => {
    return {
      tabActived,
      handleActiveNewTab,
      data: data || defaultState,
      mutateAssessment,
      mutateAssessmentBatch,
      isLoadingAssessment,
      isLoadingAssessmentBatch,
      isSameUser,
      stage,
      idActivity,
      fixedPayload,
      variablePayload,
      lessonStep,
      status,
      firstEvidence,
      projectId,
      students,
      refetchStudents,
      isLoading,
      mutateAssessmentProject,
      mutateAssessmentBatchProject,
      isLoadingAssessmentProject,
      isLoadingAssessmentBatchProject,
      isProject,
      idLessonMoment,
      idClass,
      isClassInStatus,
      classTitle,
      path,
    }
  }, [
    tabActived,
    isSameUser,
    handleActiveNewTab,
    data,
    mutateAssessment,
    mutateAssessmentBatch,
    isLoadingAssessment,
    isLoadingAssessmentBatch,
    lessonStep,
    status,
    firstEvidence,
    projectId,
    students,
    refetchStudents,
    isLoading,
    mutateAssessmentProject,
    mutateAssessmentBatchProject,
    isLoadingAssessmentProject,
    isLoadingAssessmentBatchProject,
    isProject,
    idLessonMoment,
    idClass,
    fixedPayload,
    idActivity,
    stage,
    variablePayload,
    isClassInStatus,
    classTitle,
    path,
  ])

  return (
    <AssessmentPageContext.Provider value={AssessmentPageProviderValues}>{children}</AssessmentPageContext.Provider>
  )
}

const useAssessmentPageContext = () => useContext(AssessmentPageContext)

export { AssessmentPageProvider, useAssessmentPageContext }
