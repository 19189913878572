import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import AvatarEditor from 'react-avatar-editor'
import Webcam from 'react-webcam'

import { UilCamera, UilCameraPlus, UilCheck, UilPicture } from '@iconscout/react-unicons'
import { useQueryClient } from '@tanstack/react-query'

import { useSnackbar } from '~/_context/Snackbar'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import { HTTPClient } from '~/server/HTTPClient'

import { ButtonComponent } from '../../Forms/Button/Button'
import { ModalComponentBase } from '../ModalBase/Modal'
import { IModalUploadAvatarProps } from './ModalUploadAvatar.interface'
import * as S from './ModalUploadAvatar.styles'

export const ModalUploadAvatar = ({
  open,
  title,
  selectUserId,
  setStateNewAvatar,
  setStateNewAvatarHome,
  actionCloseModal,
  type = 'student',
}: IModalUploadAvatarProps) => {
  const { showSnackbarError, showSnackbarSuccess } = useSnackbar()
  const queryClient = useQueryClient()
  const [cameraReady, setCameraReady] = useState(true)
  const editor = useRef<AvatarEditor>(null)
  const [loading, setLoading] = useState(false)
  const [statePhotos, setStatePhotos] = useState<{ image: string; avatar: File }>({
    image: '',
    avatar: new File([], ''),
  })
  const { width } = useWindowDimensions()
  const isMobile = Number(width) < 600

  const webcamRef = useRef<Webcam>(null)
  const [tab, setTab] = useState(0)
  const [facingMode, setFacingMode] = useState('user')
  const [scale, setScale] = useState(1)

  useEffect(() => {
    setCameraReady(true)
  }, [tab])

  const handleScale = (e: ChangeEvent<HTMLInputElement>) => {
    const scale = parseFloat(e.target.value)
    setScale(scale)
  }

  const handleAccessGaleryPhotos = () => document.getElementById('Photo-Galery')?.click()

  const handleCapture = () => {
    const base64String = webcamRef?.current?.getScreenshot()

    const mimeType = base64String?.split(';')[0].split(':')[1]

    const binaryString = atob(base64String?.split(',')[1] as string)

    const buffer = new ArrayBuffer(binaryString.length)
    const view = new Uint8Array(buffer)

    for (let i = 0; i < binaryString.length; i++) {
      view[i] = binaryString.charCodeAt(i)
    }

    const blob = new Blob([buffer], { type: mimeType })

    const file = new File([blob], 'arquivo.png', { type: mimeType })

    setStatePhotos({
      image: base64String as string,
      avatar: file,
    })
    setTab(2)
  }

  const handleChangeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files?.[0]) {
      setStatePhotos({
        image: URL.createObjectURL(e?.target?.files?.[0]),
        avatar: e.target?.files?.[0],
      })
    }
    setTab(2)
  }

  const handleSendImage = useCallback(async () => {
    const img = editor.current?.getImageScaledToCanvas().toDataURL()
    const rect = editor.current?.getCroppingRect()

    const base64String = img

    const mimeType = base64String?.split(';')[0].split(':')[1]

    const binaryString = atob(base64String?.split(',')[1] as string)

    const buffer = new ArrayBuffer(binaryString.length)
    const view = new Uint8Array(buffer)

    for (let i = 0; i < binaryString.length; i++) {
      view[i] = binaryString.charCodeAt(i)
    }

    const blob = new Blob([buffer], { type: mimeType })

    const file = new File([blob], 'arquivo.png', { type: mimeType })

    const fd = new FormData()

    fd.append('file', file)

    try {
      setLoading(true)
      await HTTPClient.put(`user/${selectUserId}/update-avatar`, fd)
      if (type === 'teacher') {
        localStorage.setItem('@LEKTO:avatar', img as string)
      }
      queryClient.invalidateQueries('GET_STUDENT_INFO').then()
      setLoading(false)
      setTab(0)
      setStatePhotos({
        image: '',
        avatar: new File([], ''),
      })

      actionCloseModal()
      if (setStateNewAvatar && setStateNewAvatarHome) {
        setStateNewAvatar(localStorage.getItem('@LEKTO:avatar'))
        setStateNewAvatarHome(localStorage.getItem('@LEKTO:avatar'))
      }
      showSnackbarSuccess('Foto atualizada com sucesso.')
    } catch (e) {
      setLoading(false)
      showSnackbarError('Não foi possível alterar a foto. Tente novamente ou entre em contato com a equipe da LEKTO.')
    }
  }, [
    selectUserId,
    type,
    queryClient,
    actionCloseModal,
    setStateNewAvatar,
    setStateNewAvatarHome,
    showSnackbarSuccess,
    showSnackbarError,
  ])

  const handleUserMedia = () => setTimeout(() => setCameraReady(false), 1_000)

  const handleMenuPicture = useCallback(() => {
    return (
      <S.Container>
        <S.UploadGalleryInput id="Photo-Galery" type="file" onChange={(e) => handleChangeImage(e)} />
        <S.ContentIcon>
          <UilCameraPlus color="#0095FF" size={30} />
        </S.ContentIcon>
        <S.Title>{title}</S.Title>
        <S.Description>Como você quer adicionar a foto?</S.Description>
        <ButtonComponent
          variant="solid"
          size="large"
          fullWidth={true}
          fontSize="medium"
          onClick={() => setTab(1)}
          iconStart={<UilCamera size={24} color="#FFFFFF" />}
          text="Tirar foto"
        />
        <ButtonComponent
          variant="solid"
          size="large"
          fullWidth={true}
          fontSize="medium"
          onClick={handleAccessGaleryPhotos}
          iconStart={<UilPicture size={24} color="#FFFFFF" />}
          text="Acessar Galeria de fotos"
        />
        <ButtonComponent
          variant="text"
          size="large"
          fontSize="medium"
          fullWidth={true}
          onClick={actionCloseModal}
          text="Cancelar"
        />
      </S.Container>
    )
  }, [title, actionCloseModal])

  const handleSelfieMode = useCallback(() => {
    const videoConstraints = {
      facingMode: facingMode,
      aspectRatio: isMobile ? 0.7 : 0.9,
    }

    const toggleCamera = () => {
      setFacingMode(facingMode === 'user' ? 'environment' : 'user')
    }
    return (
      <S.Container>
        <S.ContentIcon>
          <UilCameraPlus color="#0095FF" size={30} />
        </S.ContentIcon>
        <S.Title>Ajuste a foto</S.Title>
        {isMobile && (
          <ButtonComponent
            variant="solid"
            size="large"
            fullWidth={true}
            fontSize="medium"
            onClick={toggleCamera}
            text="Virar camêra"
          />
        )}
        {cameraReady && <S.Description>Preparando a câmera...</S.Description>}
        <Webcam
          onUserMedia={handleUserMedia}
          audio={false}
          mirrored={facingMode === 'user'}
          videoConstraints={videoConstraints}
          screenshotFormat="image/jpeg"
          height={isMobile ? 300 : 300}
          width={isMobile ? 200 : 300}
          ref={webcamRef}
        />
        <ButtonComponent
          variant="solid"
          size="large"
          fullWidth={true}
          fontSize="medium"
          onClick={handleCapture}
          disabled={cameraReady}
          iconStart={<UilCheck size={24} color="#FFFFFF" />}
          text=" Salvar"
        />
        <ButtonComponent
          variant="text"
          size="large"
          fontSize="medium"
          fullWidth={true}
          onClick={() => setTab(0)}
          text="Voltar"
        />
      </S.Container>
    )
  }, [facingMode, cameraReady, isMobile])

  const handlePreviewImage = useCallback(() => {
    return (
      <S.Container className="styled-scroll-s">
        <S.ContentIcon>
          <UilCameraPlus color="#0095FF" size={30} />
        </S.ContentIcon>
        <S.Title>Visualize a foto:</S.Title>
        <AvatarEditor
          ref={editor}
          image={statePhotos.image}
          width={250}
          height={250}
          border={50}
          color={[255, 255, 255, 0.6]}
          scale={scale}
          borderRadius={150}
          rotate={0}
        />
        Zoom:{' '}
        <input name="scale" type="range" onChange={handleScale} min={'0.5'} max="2" step="0.01" defaultValue={scale} />
        <ButtonComponent
          variant="solid"
          size="large"
          fullWidth={true}
          fontSize="medium"
          onClick={handleSendImage}
          loading={loading}
          iconStart={<UilCheck size={24} color="#FFFFFF" />}
          text="Confirmar"
        />
        <ButtonComponent
          variant="text"
          size="large"
          fontSize="medium"
          fullWidth={true}
          onClick={() => setTab(0)}
          text="Tirar outra foto"
        />
        <ButtonComponent
          variant="text"
          size="large"
          fontSize="medium"
          fullWidth={true}
          onClick={() => {
            actionCloseModal()
            setTab(0)
          }}
          text="Cancelar"
        />
      </S.Container>
    )
  }, [handleSendImage, scale, loading, statePhotos, actionCloseModal])

  const handleChangeTab = useCallback(() => {
    if (tab === 0) {
      return handleMenuPicture()
    }
    if (tab === 1) {
      return handleSelfieMode()
    }
    if (tab === 2) {
      return handlePreviewImage()
    }
    return <h1>Carregando....</h1>
  }, [tab, handlePreviewImage, handleSelfieMode, handleMenuPicture])

  return (
    <ModalComponentBase
      open={open}
      actionCloseModal={() => {
        actionCloseModal()
        setTab(0)
      }}
      maxWidth={isMobile ? '80%' : '100%'}
      width={isMobile ? '80%' : 'initial'}
    >
      {handleChangeTab()}
    </ModalComponentBase>
  )
}
