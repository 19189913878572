import { useEffect, useRef, useState } from 'react'

import { UilAngleDown, UilAngleUp } from '@iconscout/react-unicons'

import { DividerComponent, IconComponent } from '~/components'

import { IDropdownSkillComponentProps } from './DropdownSkill.interfaces'
import * as S from './DropdownSkill.styles'

export const DropdownSkillComponent = ({
  iconSkill,
  txSkillName,
  txCompetency,
  iconCompetency,
  isOpen,
  onToggle,
}: IDropdownSkillComponentProps) => {
  const [openDropdownSkill, setOpenDropdownSkill] = useState<boolean>(false)
  const dropdownRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setOpenDropdownSkill(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  useEffect(() => {
    if (isOpen !== undefined) {
      setOpenDropdownSkill(isOpen)
    }
  }, [isOpen])

  const isOpenDropdown = openDropdownSkill
  const toggleDropdown = () => {
    if (onToggle) {
      onToggle()
    } else {
      setOpenDropdownSkill(!openDropdownSkill)
    }
  }

  return (
    <S.DropdownSkillContainer
      ref={dropdownRef}
      open={isOpenDropdown}
      onClick={() => {
        toggleDropdown()
      }}
    >
      <S.ContentPreview>
        <S.Wrapper>
          <IconComponent color="primary" fontWeight="normal" code={iconSkill} size="xsmall" />

          <S.Title variant="caption">{txSkillName}</S.Title>
        </S.Wrapper>

        {isOpenDropdown ? <UilAngleUp size={20} color="#0095FF" /> : <UilAngleDown size={20} color="#0095FF" />}
      </S.ContentPreview>

      <S.ContentDescriptions open={isOpenDropdown}>
        <DividerComponent color="#90D1FF" />

        {txCompetency && (
          <>
            <S.TextLabel>Competência</S.TextLabel>

            <S.Wrapper>
              <IconComponent color="primary" fontWeight="normal" code={iconCompetency} size="xsmall" />

              <S.Title variant="caption">{txCompetency}</S.Title>
            </S.Wrapper>
          </>
        )}
      </S.ContentDescriptions>
    </S.DropdownSkillContainer>
  )
}
