import { useState } from 'react'

import { UilArrowRight, UilCheck, UilInfoCircle } from '@iconscout/react-unicons'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import * as S from './StepsTracking.styles'

interface IStepsTracking {
  status: string
}

interface IStepsTrackingChip {
  stepNumber: number
  text: string
  variant: 'onStep' | 'disable' | 'checked'
  status: string
  isOffline?: boolean
}

export const handleStatusStep = (status: string) => {
  switch (status) {
    case 'AGEN':
      return 'Planning'
    case 'AUPL':
      return 'Action'
    case 'PEND':
      return 'Planning'
    case 'INIC':
      return 'Action'
    case 'AUPE':
      return 'Assessment'
    case 'AVPE':
      return 'Assessment'
    default:
      return 'Finish'
  }
}

export const StepsTrackingComponent = ({ status }: IStepsTracking) => {
  const [openAction, setOpenAction] = useState(false)
  const [openAssessment, setOpenAssessment] = useState(false)
  const [openPlanning, setOpenPlanning] = useState(false)

  const { width } = useWindowDimensions()
  const isMobile = width && width < 768

  const getStepVariant = (stepStatus: string) => {
    const currentStatus = handleStatusStep(status)

    if (currentStatus === stepStatus) {
      return 'onStep'
    } else if (
      (currentStatus === 'Planning' && stepStatus === 'Action') ||
      (currentStatus === 'Action' && stepStatus === 'Assessment') ||
      (currentStatus === 'Planning' && stepStatus === 'Assessment')
    ) {
      return 'disable'
    } else {
      return 'checked'
    }
  }
  return (
    <>
      {/* {openPlanning && (
        <ModalStepTrackingInformation
          stepStatus={'Planning'}
          open={openPlanning}
          actionCloseModal={() => setOpenPlanning(!openPlanning)}
        />
      )}
      {openAction && (
        <ModalStepTrackingInformation
          stepStatus={'Action'}
          open={openAction}
          actionCloseModal={() => setOpenAction(!openAction)}
        />
      )}
      {openAssessment && (
        <ModalStepTrackingInformation
          stepStatus={'Assessment'}
          open={openAssessment}
          actionCloseModal={() => setOpenAssessment(!openAssessment)}
        />
      )} */}
      <S.Steps>
        <S.StepItem
          variant={getStepVariant('Planning')}
          className={'planning'}
          onClick={() => setOpenPlanning(!openPlanning)}
        >
          {getStepVariant('Planning') === 'checked' && <UilCheck size={16} />}
          <S.StepLabel>
            {isMobile ? String('1. Planejamento').substring(0, 12 - 1) + '...' : '1. Planejamento'}
          </S.StepLabel>
        </S.StepItem>
        <S.ArrowOne>
          <UilArrowRight size={20} color={'#7C8189'} />
        </S.ArrowOne>
        <S.StepItem variant={getStepVariant('Action')} onClick={() => setOpenAction(!openAction)}>
          {getStepVariant('Action') === 'checked' && <UilCheck size={16} />}
          <S.StepLabel>
            {isMobile ? String('2. Ação e Documentação').substring(0, 12 - 1) + '...' : '2. Ação e Documentação'}
          </S.StepLabel>
        </S.StepItem>
        <S.ArrowTwo>
          <UilArrowRight size={20} color={'#7C8189'} />
        </S.ArrowTwo>
        <S.StepItem
          variant={getStepVariant('Assessment')}
          className={'assessment'}
          onClick={() => setOpenAssessment(!openAssessment)}
        >
          {getStepVariant('Assessment') === 'checked' && <UilCheck size={16} />}
          <S.StepLabel>3. Avaliação</S.StepLabel>
        </S.StepItem>
      </S.Steps>
    </>
  )
}

export const StepsTrackingChipComponent = ({ stepNumber, text, variant, status, isOffline }: IStepsTrackingChip) => {
  const currentStatus = handleStatusStep(status)
  const isDisabled = ['Planning', 'Action'].includes(currentStatus)
  const isChecked = !isDisabled

  return (
    <S.Chip variant={variant}>
      {isChecked && isOffline === false && <UilCheck size={16} />}
      {stepNumber}. {text}
      <UilInfoCircle size={16} />
    </S.Chip>
  )
}
