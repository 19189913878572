import { Link } from 'react-router-dom'

import { Accordion, AccordionSummary, Backdrop, Box, List, ListItem, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled, { css } from 'styled-components'

import { colorsStepsActived, colorsStepsDisabled } from '~/validations'

import theme from '~/styles/theme'

export const AccordionContainer = styled(Accordion)`
  padding: 24px;
  box-shadow: none;
  border-top: 1px solid ${({ theme }) => theme.colors.midMedium};
  border-bottom: 1px solid ${({ theme }) => theme.colors.midMedium};
  margin: 0 !important;

  .general {
    padding: 18px;
  }

  .overall-guidance {
    padding: 18px;
  }

  & .muiaccordion-root:before {
    display: none;
  }

  .css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
    margin: 0;
  }
`
export const AccordionHeader = styled(AccordionSummary)`
  display: flex;
  justify-content: space-between;
`

export const StepOrientationContainer = styled.div`
  padding: 18px;
`
export const StepOrientationContainerTitle = styled.div`
  padding: 0px 18px;
`

export const AccordionTitle = styled(Typography)<{ type: 'default' | 'generalStep' | 'firstStep' }>`
  font-weight: 700 !important;
  font-size: 20px !important;
  display: flex;
  align-items: baseline;
  gap: 8px;
  line-height: 120%;
  ${({ type, theme }) =>
    type === 'default' &&
    css`
      color: ${theme.colors.actionPure};
    `};
  ${({ type, theme }) =>
    type === 'generalStep' &&
    css`
      color: ${theme.colors.primaryPure};
    `};
  ${({ type, theme }) =>
    type === 'firstStep' &&
    css`
      color: ${theme.colors.primaryPure};
    `};
`

export const AccordionTitlenoNCollapsible = styled(Typography)<{ type: 'default' | 'generalStep' | 'firstStep' }>`
  font-weight: 700 !important;
  font-size: 20px !important;
  display: flex;
  align-items: baseline;
  gap: 8px;
  line-height: 120%;
  ${({ type, theme }) =>
    type === 'default' &&
    css`
      color: ${theme.colors.actionPure};
    `};
  ${({ type, theme }) =>
    type === 'generalStep' &&
    css`
      color: ${theme.colors.primaryPure};
    `};
  ${({ type, theme }) =>
    type === 'firstStep' &&
    css`
      color: ${theme.colors.primaryPure};
      padding-top: 16px;
    `};
`

export const AccordionText = styled.div`
  text-align: left;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: ${theme.colors.lowPure};

  p {
    margin: 12px 0;
  }
`

export const CircleIcon = styledMUI(Box)(() => ({
  height: '24px',
  width: '24px',
  display: 'flex',
  justifyContent: 'center',
  cursor: 'pointer',
  alignItems: 'center',
  backgroundColor: `${theme.colors.neutralMid.medium}`,
  borderRadius: '50%',
}))

export const ContentCards = styledMUI(List)<{ countItens: number }>(({ countItens }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: countItens > 3 ? 'space-around' : 'flex-start',
  alignItems: 'center',
  gap: '8px',
  overflowX: 'auto',
}))

export const CardLink = styledMUI(Link)(() => ({
  textDecoration: 'none',
}))

export const Card = styledMUI(ListItem)<{ type: 'default' | 'firstStep' | 'secondStep' | 'thirdStep' }>(({ type }) => ({
  height: '110px',
  width: '192px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: colorsStepsDisabled(type),
  borderRadius: '8px',
  gap: '8px',
}))

export const Loading = styledMUI(Backdrop)(() => ({}))

export const TextCard = styledMUI(Typography)<{
  type: 'default' | 'firstStep' | 'secondStep' | 'thirdStep'
}>(({ type }) => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '150%',
  color: colorsStepsActived(type),
  textAlign: 'center',
  WebkitLineClamp: 3,
  WebkitBoxOrient: 'vertical',
  display: '-webkit-box',
  overflow: 'hidden',
}))

export const Arrow = styledMUI(Box)<{ enabled: boolean }>(({ enabled }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: enabled ? `${theme.colors.highDark}` : `${theme.colors.accentPure}`,
  width: '24px',
  height: '24px',
  color: `${theme.colors.highPure}`,
  borderRadius: '50%',
}))

export const PutStart = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '16px',
  paddingBottom: '20px',
}))

export const ReferenceContainer = styled.div`
  .reference {
    margin-top: 8px;
  }
  width: 860px;
`

export const PutEnd = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: '16px',
  paddingBottom: '20px',
}))
