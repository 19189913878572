import { useEffect, useRef, useState } from 'react'
import { ConnectDropTarget, useDrop } from 'react-dnd'

import { UilArrowLeft, UilArrowRight } from '@iconscout/react-unicons'
import { Box } from '@mui/material'

import { Group, IGetStudentsGroupLesson } from '~/services/Students/types'

import { DropdownSkillComponent } from '~/components'
import { IAssessmentProps, SelectedStudentProp } from '~/components/Forms/Assessment/Assessment.interfaces'
import { AssessmentStep } from '~/components/Forms/Assessment/AssessmentStep'
import CustomDragLayer from '~/components/Modals/ModalStudentManagement/CustomDragLayer'

import { CardStudentComponent } from '~/containers/private/Fund1/LessonAssessment'

import * as S from './Assessment.styles'

export function getTypeSpecificId(student: Group, type: 'lesson' | 'project'): number {
  if (type === 'lesson') {
    return student?.idLessonMomentGroup
  } else {
    return student?.idProjectMomentGroup
  }
}

export function getTypeSpecificIdMoment(student: Group, type: 'lesson' | 'project'): number {
  if (type === 'lesson') {
    return student?.idLessonMomentActivity
  } else {
    return student?.idProjectMomentStage
  }
}

export function getTypeSpecificAssessment(student: Group, type: 'lesson' | 'project') {
  if (type === 'lesson') {
    return student?.assessment
  } else {
    return student?.projectGroupAssessment
  }
}

export const AssessmentComponent = ({
  handleDrop,
  handleDropBatch,
  txEvidenceName,
  idEvidence,
  txEvidenceDescription,
  txCompentencyName,
  indexEvidence,
  lengthEvidence,
  skillType = 'VAR',
  students,
  type,
  idActivity,
  disabled,
  txIconCompetency,
  txIconSkill,
}: IAssessmentProps) => {
  const [studentsState, setStudentsState] = useState<IGetStudentsGroupLesson>(students)
  const [isDragging, setIsDragging] = useState(false)
  const [draggedStudentId, setDraggedStudentId] = useState<number>(0)
  const isDraggingRef = useRef(isDragging)

  useEffect(() => {
    setStudentsState(students)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateStudents = (updatedStudents: Group[]) => {
    setStudentsState((prevState) => {
      const updatedGroup = prevState.group.map((student) => {
        const updatedStudent = updatedStudents.find((s) => s.idStudent === student.idStudent)

        if (updatedStudent) {
          return { ...student, isSelected: updatedStudent.isSelected }
        }

        return student
      })

      return {
        ...prevState,
        group: updatedGroup,
      }
    })
  }

  const handleSelectStudent = (idUserStudent: number) => {
    setStudentsState((prevStudents) => {
      const updatedStudents = prevStudents.group.map((student) => {
        if (student.idUserStudent === idUserStudent) {
          return { ...student, isSelected: !student.isSelected }
        }
        return student
      })

      return {
        ...prevStudents,
        group: updatedStudents,
      }
    })
  }

  const getGroupByAssessment = (coAssessment: string) => {
    const { group, allAssessments } = studentsState
    const filteredStudents: Group[] = []
    if (!allAssessments || allAssessments?.length === 0) return filteredStudents

    for (const student of group) {
      const matchingAssessment = allAssessments.find(
        (assessment) =>
          assessment.idStudent === student.idStudent &&
          assessment.coAssessment === coAssessment &&
          assessment.idEvidence === Number(idEvidence),
      )
      if (matchingAssessment && getTypeSpecificIdMoment(student, type) === idActivity) {
        filteredStudents.push(student)
      }
    }
    return filteredStudents
  }

  const totalStudents = getGroupByAssessment('NO').length

  const handleUpdateStudents = (studentId: number, coAssessment: string, idEvidence: number) => {
    setStudentsState((prevState) => {
      const updatedGroup = prevState.group.map((student) => {
        if (student.idStudent === studentId) {
          const updatedAssessments = getTypeSpecificAssessment(student, type)?.map((assessment) => {
            if (assessment.idEvidence === idEvidence) {
              return {
                ...assessment,
                coAssessment: coAssessment,
              }
            }
            return assessment
          })
          return {
            ...student,
            [type === 'lesson' ? 'assessment' : 'projectGroupAssessment']: updatedAssessments,
          }
        }
        return student
      })

      const updatedAllAssessments = prevState.allAssessments.map((assessment) => {
        if (assessment.idStudent === studentId && assessment.idEvidence === idEvidence) {
          return {
            ...assessment,
            coAssessment: coAssessment,
          }
        }
        return assessment
      })

      return {
        group: updatedGroup,
        allAssessments: updatedAllAssessments,
      }
    })
  }

  const handleUpdateStudentsBatch = (students: SelectedStudentProp[], coAssessment: string, idEvidence: number) => {
    setStudentsState((prevState) => {
      const updatedGroup = prevState.group.map((student) => {
        const studentToUpdate = students.find((s) => s.idStudent === student.idStudent)

        if (studentToUpdate) {
          const updatedAssessments = getTypeSpecificAssessment(student, type)?.map((assessment) => {
            if (assessment && assessment.idEvidence === idEvidence) {
              return {
                ...assessment,
                coAssessment,
              }
            }
            return assessment
          })

          return {
            ...student,
            [type === 'lesson' ? 'assessment' : 'projectGroupAssessment']: updatedAssessments,
          }
        }
        setIsDragging(false)
        isDraggingRef.current = false
        setDraggedStudentId(null)
        return student
      })

      const updatedAllAssessments = prevState.allAssessments.map((assessment) => {
        const studentToUpdate = students.find((s) => s.idStudent === assessment.idStudent)

        if (studentToUpdate && assessment.idEvidence === idEvidence) {
          return {
            ...assessment,
            coAssessment,
          }
        }
        return assessment
      })

      setTimeout(() => {
        updatedGroup.forEach((student) => {
          if (student.isSelected) {
            student.isSelected = false
          }
        })
      }, 500)

      return {
        group: updatedGroup,
        allAssessments: updatedAllAssessments,
      }
    })
  }

  const [currentIndex, setCurrentIndex] = useState(0)
  const itemsPerPage = 6

  const handlePrevClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1)
    }
  }

  const handleNextClick = () => {
    if (currentIndex < totalStudents - itemsPerPage) {
      setCurrentIndex(currentIndex + 1)
    }
  }

  const isPrevDisabled = currentIndex === 0
  const isNextDisabled = currentIndex >= totalStudents - itemsPerPage

  const [, dropNotObserved] = useDrop(() => ({
    accept: 'CARD',
    hover: () => {
      setIsDragging(true)
      isDraggingRef.current = true
      return true
    },
    canDrop: () => {
      return !disabled
    },
    drop: async (item) => {
      try {
        const selectedStudent = [
          {
            idMomentGroup: Number(item?.object?.idLessonMomentGroup || Number(item?.object?.idProjectMomentGroup)),
            coAssessment: 'NO',
            idEvidence: Number(idEvidence),
          },
        ]
        setIsDragging(false)
        isDraggingRef.current = false
        setDraggedStudentId(null)
        handleDropBatch(selectedStudent)
        setIsDragging(false)
        isDraggingRef.current = false
        setDraggedStudentId(null)
        handleUpdateStudents(Number(item?.object?.idStudent), 'NO', Number(idEvidence))
      } catch (error) {
        // Lidar com erros, se necessário
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  }))

  const [, dropNotObservedBatch] = useDrop(() => ({
    accept: 'CARD',
    hover: (item) => {
      setIsDragging(true)
      isDraggingRef.current = true
      setDraggedStudentId(item.object.idStudent)
      return true
    },
    canDrop: () => {
      return !disabled
    },
    drop: async (item) => {
      try {
        const newSelectedStudents = await new Promise<SelectedStudentProp[]>((resolve) => {
          setStudentsState((prevStudents) => {
            const selectedStudent = prevStudents.group
              .filter((student) => student.isSelected)
              .map((student) => ({
                idStudent: student.idStudent,
                idMomentGroup: Number(student.idLessonMomentGroup || Number(student.idProjectMomentGroup)),
                coAssessment: 'NO',
                idEvidence: Number(idEvidence),
              }))
            resolve(selectedStudent)

            setIsDragging(false)
            isDraggingRef.current = false
            setDraggedStudentId(null)
            return {
              ...prevStudents,
              group: prevStudents.group,
            }
          })
        })

        handleDropBatch(newSelectedStudents)
        handleUpdateStudentsBatch(newSelectedStudents, 'NO', Number(idEvidence))
        setIsDragging(false)
        isDraggingRef.current = false
        setDraggedStudentId(null)
      } catch (error) {
        // Lidar com erros, se necessário
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  }))

  const [, dropToDevelop] = useDrop(() => ({
    accept: 'CARD',
    hover: () => {
      setIsDragging(true)
      isDraggingRef.current = true
      return true
    },
    canDrop: () => {
      return !disabled
    },
    drop: async (item) => {
      try {
        const selectedStudent = [
          {
            idMomentGroup: Number(item?.object?.idLessonMomentGroup || Number(item?.object?.idProjectMomentGroup)),
            coAssessment: 'AD',
            idEvidence: Number(idEvidence),
          },
        ]
        setIsDragging(false)
        isDraggingRef.current = false
        setDraggedStudentId(null)
        handleDropBatch(selectedStudent)
        handleUpdateStudents(Number(item?.object?.idStudent), 'AD', Number(idEvidence))
        setIsDragging(false)
        isDraggingRef.current = false
      } catch (error) {
        // Lidar com erros, se necessário
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  }))

  const [, dropToDevelopBatch] = useDrop(() => ({
    accept: 'CARD',
    hover: (item) => {
      setIsDragging(true)
      isDraggingRef.current = true
      setDraggedStudentId(item.object.idStudent)
      return true
    },
    canDrop: () => {
      return !disabled
    },
    drop: async (item: { id: string; object: { idStudent: string } }) => {
      try {
        const newSelectedStudents = await new Promise<SelectedStudentProp[]>((resolve) => {
          setStudentsState((prevStudents) => {
            const selectedStudent = prevStudents.group
              .filter((student) => student.isSelected)
              .map((student) => ({
                idStudent: student.idStudent,
                idMomentGroup: Number(student.idLessonMomentGroup || Number(student.idProjectMomentGroup)),
                coAssessment: 'AD',
                idEvidence: Number(idEvidence),
              }))
            resolve(selectedStudent)
            setIsDragging(false)
            isDraggingRef.current = false
            setDraggedStudentId(null)

            return {
              ...prevStudents,
              group: prevStudents.group,
            }
          })
        })

        handleDropBatch(newSelectedStudents)
        handleUpdateStudentsBatch(newSelectedStudents, 'AD', Number(idEvidence))
      } catch (error) {
        // Lidar com erros, se necessário
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }))

  const [, dropInEarlyDevelopment] = useDrop(() => ({
    accept: 'CARD',
    hover: () => {
      setIsDragging(true)
      isDraggingRef.current = true
      return true
    },
    canDrop: () => {
      return !disabled
    },
    drop: async (item) => {
      try {
        const selectedStudent = [
          {
            idMomentGroup: Number(item?.object?.idLessonMomentGroup || Number(item?.object?.idProjectMomentGroup)),
            coAssessment: 'EI',
            idEvidence: Number(idEvidence),
          },
        ]
        setIsDragging(false)
        isDraggingRef.current = false
        setDraggedStudentId(null)
        handleDropBatch(selectedStudent)
        handleUpdateStudents(Number(item?.object?.idStudent), 'EI', Number(idEvidence))
        setIsDragging(false)
        isDraggingRef.current = false
      } catch (error) {
        // Lidar com erros, se necessário
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  }))

  const [, dropInEarlyDevelopmentBatch] = useDrop(() => ({
    accept: 'CARD',
    hover: (item) => {
      setIsDragging(true)
      isDraggingRef.current = true
      setDraggedStudentId(item.object.idStudent)
      return true
    },
    canDrop: () => {
      return !disabled
    },
    drop: async (item: { id: string; object: { idStudent: string } }) => {
      try {
        const newSelectedStudents = await new Promise<SelectedStudentProp[]>((resolve) => {
          setStudentsState((prevStudents) => {
            const selectedStudent = prevStudents.group
              .filter((student) => student.isSelected)
              .map((student) => ({
                idStudent: student.idStudent,
                idMomentGroup: Number(student.idLessonMomentGroup || Number(student.idProjectMomentGroup)),
                coAssessment: 'EI',
                idEvidence: Number(idEvidence),
              }))
            resolve(selectedStudent)
            setIsDragging(false)
            isDraggingRef.current = false
            setDraggedStudentId(null)

            return {
              ...prevStudents,
              group: prevStudents.group,
            }
          })
        })

        handleDropBatch(newSelectedStudents)
        handleUpdateStudentsBatch(newSelectedStudents, 'EI', Number(idEvidence))
      } catch (error) {
        // Lidar com erros, se necessário
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }))

  const [, dropUnderDevelopment] = useDrop(() => ({
    accept: 'CARD',
    hover: () => {
      setIsDragging(true)
      isDraggingRef.current = true
      return true
    },
    canDrop: () => {
      return !disabled
    },
    drop: async (item) => {
      try {
        const selectedStudent = [
          {
            idMomentGroup: Number(item?.object?.idLessonMomentGroup || Number(item?.object?.idProjectMomentGroup)),
            coAssessment: 'ED',
            idEvidence: Number(idEvidence),
          },
        ]
        setIsDragging(false)
        isDraggingRef.current = false
        setDraggedStudentId(null)
        handleDropBatch(selectedStudent)
        handleUpdateStudents(Number(item?.object?.idStudent), 'ED', Number(idEvidence))
        setIsDragging(false)
        isDraggingRef.current = false
      } catch (error) {
        // Lidar com erros, se necessário
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  }))

  const [, dropUnderDevelopmentBatch] = useDrop(() => ({
    accept: 'CARD',
    hover: (item) => {
      setIsDragging(true)
      isDraggingRef.current = true
      setDraggedStudentId(item.object.idStudent)
      return true
    },
    canDrop: () => {
      return !disabled
    },
    drop: async (item: { id: string; object: { idStudent: string } }) => {
      try {
        const newSelectedStudents = await new Promise<SelectedStudentProp[]>((resolve) => {
          setStudentsState((prevStudents) => {
            const selectedStudent = prevStudents.group
              .filter((student) => student.isSelected)
              .map((student) => ({
                idStudent: student.idStudent,
                idMomentGroup: Number(student.idLessonMomentGroup || Number(student.idProjectMomentGroup)),
                coAssessment: 'ED',
                idEvidence: Number(idEvidence),
              }))
            resolve(selectedStudent)
            setIsDragging(false)
            isDraggingRef.current = false
            setDraggedStudentId(null)

            return {
              ...prevStudents,
              group: prevStudents.group,
            }
          })
        })

        handleDropBatch(newSelectedStudents)
        handleUpdateStudentsBatch(newSelectedStudents, 'ED', Number(idEvidence))
      } catch (error) {
        // Lidar com erros, se necessário
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }))

  const [, dropDeveloped] = useDrop(() => ({
    accept: 'CARD',
    hover: () => {
      setIsDragging(true)
      isDraggingRef.current = true
      return true
    },
    canDrop: () => {
      return !disabled
    },
    drop: async (item) => {
      try {
        const selectedStudent = [
          {
            idMomentGroup: Number(item?.object?.idLessonMomentGroup || Number(item?.object?.idProjectMomentGroup)),
            coAssessment: 'DV',
            idEvidence: Number(idEvidence),
          },
        ]
        setIsDragging(false)
        isDraggingRef.current = false
        setDraggedStudentId(null)
        handleDropBatch(selectedStudent)
        handleUpdateStudents(Number(item?.object?.idStudent), 'DV', Number(idEvidence))
        setIsDragging(false)
        isDraggingRef.current = false
      } catch (error) {
        // Lidar com erros, se necessário
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  }))

  const [, dropDevelopedBatch] = useDrop(() => ({
    accept: 'CARD',
    hover: (item) => {
      setIsDragging(true)
      isDraggingRef.current = true
      setDraggedStudentId(item.object.idStudent)
      return true
    },
    canDrop: () => {
      return !disabled
    },
    drop: async (item: { id: string; object: { idStudent: string } }) => {
      try {
        const newSelectedStudents = await new Promise<SelectedStudentProp[]>((resolve) => {
          setStudentsState((prevStudents) => {
            const selectedStudent = prevStudents.group
              .filter((student) => student.isSelected)
              .map((student) => ({
                idStudent: student.idStudent,
                idMomentGroup: Number(student.idLessonMomentGroup || Number(student.idProjectMomentGroup)),
                coAssessment: 'DV',
                idEvidence: Number(idEvidence),
              }))
            resolve(selectedStudent)
            setIsDragging(false)
            isDraggingRef.current = false
            setDraggedStudentId(null)
            return {
              ...prevStudents,
              group: prevStudents.group,
            }
          })
        })

        handleDropBatch(newSelectedStudents)
        handleUpdateStudentsBatch(newSelectedStudents, 'DV', Number(idEvidence))
      } catch (error) {
        // Lidar com erros, se necessário
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }))

  function getDropRef(typeRef: string): ConnectDropTarget {
    const hasSelectedStudents = studentsState?.group?.some((student) => student.isSelected === true)
    if (typeRef === 'toDevelop') {
      return hasSelectedStudents ? dropToDevelopBatch : dropToDevelop
    } else if (typeRef === 'inEarlyDevelopment') {
      return hasSelectedStudents ? dropInEarlyDevelopmentBatch : dropInEarlyDevelopment
    } else if (typeRef === 'underDevelopment') {
      return hasSelectedStudents ? dropUnderDevelopmentBatch : dropUnderDevelopment
    } else if (typeRef === 'developed') {
      return hasSelectedStudents ? dropDevelopedBatch : dropDeveloped
    } else if (typeRef === 'notOberseved') {
      return hasSelectedStudents ? dropNotObservedBatch : dropNotObserved
    }
    throw new Error(`Tipo '${typeRef}' não suportado`)
  }

  return (
    <S.ContentAvaliar>
      <S.ContentInfos>
        <Box display={'flex'} alignItems={'center'} gap={'8px'}>
          <S.ContentStepToStep>
            {indexEvidence ? (
              <S.TextContentStepToStep>
                {indexEvidence} de {lengthEvidence}
              </S.TextContentStepToStep>
            ) : (
              <S.TextContentStepToStep>1 de 1</S.TextContentStepToStep>
            )}
          </S.ContentStepToStep>

          <S.TextLabelEvidence color="#000" variant="caption">
            ASET da Aula
          </S.TextLabelEvidence>
        </Box>

        <DropdownSkillComponent
          txSkillName={txEvidenceName}
          txSkillDescription={txEvidenceDescription}
          txCompetency={txCompentencyName}
          iconCompetency={txIconCompetency}
          iconSkill={txIconSkill}
        />
      </S.ContentInfos>

      <S.TextDescription>
        Demonstra interesse e disposição para lidar com situações desafiadoras que não possuem uma resposta definida e
        demandam novas abordagens.
      </S.TextDescription>

      <S.ContentStudents disabled={disabled} ref={getDropRef('notOberseved')}>
        <S.TextLabelStudents>Não observado</S.TextLabelStudents>
        <S.WrapperStudentList>
          <S.PutStart>
            <S.Arrow onClick={handlePrevClick} enabled={isPrevDisabled}>
              <UilArrowLeft size="20" />
            </S.Arrow>
          </S.PutStart>
          <S.ListStudents>
            {getGroupByAssessment('NO')
              .slice(currentIndex, currentIndex + itemsPerPage)
              .map((student: Group) => (
                <div
                  key={student.idMomentGroup}
                  onMouseOver={() => setDraggedStudentId(student.idUserStudent)}
                  onFocus={() => setDraggedStudentId(student.idUserStudent)}
                  onClick={() => handleSelectStudent(student.idUserStudent)}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      handleSelectStudent(student.idUserStudent)
                    }
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <CardStudentComponent
                    key={getTypeSpecificId(student, type)}
                    object={student}
                    id={getTypeSpecificId(student, type)}
                    photoUrl={student.studentClass?.student?.txImagePath}
                    txName={student.studentClass?.student?.txName}
                    length={studentsState.group.length}
                    students={studentsState.group?.filter((student) => student.isSelected)}
                    isDragging={isDraggingRef.current}
                    idBatch={draggedStudentId}
                  />
                </div>
              ))}
            <CustomDragLayer students={studentsState.group?.filter((student) => student.isSelected)} />
          </S.ListStudents>
          <S.PutEnd>
            <S.Arrow onClick={handleNextClick} enabled={isNextDisabled}>
              <UilArrowRight size="20" />
            </S.Arrow>
          </S.PutEnd>
        </S.WrapperStudentList>
      </S.ContentStudents>

      <S.ContentStepsAssessment disabled={disabled}>
        <AssessmentStep
          stepAssessment="toDevelop"
          labelText="A desenvolver"
          updateStudents={updateStudents}
          dropRef={getDropRef('toDevelop')}
          students={getGroupByAssessment('AD')}
          group={studentsState.group}
          type={type}
          isDragging={isDraggingRef.current}
        />

        <AssessmentStep
          stepAssessment="inEarlyDevelopment"
          labelText="Em início de desenvolvimento"
          updateStudents={updateStudents}
          dropRef={getDropRef('inEarlyDevelopment')}
          students={getGroupByAssessment('EI')}
          group={studentsState.group}
          type={type}
          isDragging={isDraggingRef.current}
        />

        <AssessmentStep
          stepAssessment="underDevelopment"
          labelText="Em desenvolvimento"
          updateStudents={updateStudents}
          group={studentsState.group}
          dropRef={getDropRef('underDevelopment')}
          students={getGroupByAssessment('ED')}
          type={type}
          isDragging={isDraggingRef.current}
        />

        <AssessmentStep
          stepAssessment="developed"
          labelText="Desenvolvido"
          updateStudents={updateStudents}
          dropRef={getDropRef('developed')}
          group={studentsState.group}
          students={getGroupByAssessment('DV')}
          type={type}
          isDragging={isDraggingRef.current}
        />
      </S.ContentStepsAssessment>
    </S.ContentAvaliar>
  )
}
