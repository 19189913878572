import { Box, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

export const Container = styledMUI(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#F9FAFC',
  padding: '16px',
  borderRadius: '8px',
  gap: '16px',
  boxShadow: 'none',
  cursor: 'pointer',
}))

export const WrapperInputFilter = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

export const WrapperLabel = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'left',
  gap: '4px',
}))

export const Label = styledMUI(Typography)(() => ({
  fontSize: '14px',
  lineHeight: '14px',
  fontWeight: 400,
}))

export const LabelOption = styledMUI(Typography)(() => ({
  fontSize: '12px',
  lineHeight: '14.4px',
  fontWeight: 400,
  color: '#A3A3A3',
}))

export const CardStudent = styledMUI(Box)<{ selected: boolean }>(({ selected }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  background: `${selected ? '#E8F6FF' : '#fff'}`,
  borderRadius: '8px',
  width: '122px',
  height: '90px',
  border: `${selected && '2px solid #0095FF'}`,
}))

export const PutStart = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '16px',
  paddingBottom: '20px',
}))

export const PutEnd = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: '16px',
  paddingBottom: '20px',
}))

export const Arrow = styledMUI(Box)<{ disabled: boolean }>(({ disabled }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: disabled ? '#ccc' : '#0095FF',
  width: '24px',
  height: '24px',
  color: '#fff',
  borderRadius: '50%',
}))

export const WrapperStudentList = styledMUI(Box)(() => ({
  height: '80px',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
}))
