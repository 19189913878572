import { Accordion, AccordionDetails, AccordionSummary, Box, List, ListItem, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'

import { colorsStepsActivedVariant } from '~/validations'

import theme from '~/styles/theme'

interface ICardContainerProps {
  isMobile: boolean
}

export const ContentList = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  backgroundColor: theme.colors.highPure,
  padding: '24px',
}))

export const ContainerScroll = styled.div`
  overflow-y: auto;
  height: 100vh;
`
export const StepHeader = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  alignSelf: 'stretch',
  marginTop: '24px',
}))

export const ContentNumber = styled.div`
  display: flex;
  height: 24px;
  width: 24px;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.highPure};
  background-color: ${({ theme }) => theme.colors.midDark};
  z-index: 1;
`

export const StepNumber = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
`

export const TitleLessonStep = styledMUI(Typography)(() => ({
  fontFamily: 'Inter',
  fontSize: '20px',
  fontWeight: 700,
  lineHeight: '120%',
  maxWidth: '95%',
  color: `${theme.colors.lowPure}`,
}))

export const CardDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 8px;
`

export const CardSliderResumeContainer = styled.div<ICardContainerProps>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: 16px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  user-select: none;
`
export const AccordionContainer = styledMUI(Accordion)(() => ({
  backgroundColor: `${theme.colors.midLight}`,
  borderRadius: '8px',
  width: '100%',
  boxShadow: 'none',
  marginTop: '5px',

  '&.MuiAccordion-root:before': {
    display: 'none',
  },
}))

export const AccordionDetailsContent = styledMUI(AccordionDetails)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  width: '100%',
  padding: '0px 16px 16px',
  margin: '0px',
}))

export const AccordionSummaryContent = styledMUI(AccordionSummary)(() => ({
  padding: '0px 16px',
  margin: '0px',
  width: '100%',
}))

export const OrientationTitle = styledMUI(Typography)(() => ({
  color: `${theme.colors.lowDark}`,
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '120%',
}))

export const Title = styledMUI(Typography)<{ type: 'firstStep' | 'secondStep' | 'thirdStep' | 'fourthStep' }>(
  ({ type }) => ({
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '120%',
    color: colorsStepsActivedVariant(type),
  }),
)

export const TextParagraph = styledMUI(Box)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '150%',
  color: `${theme.colors.lowPure}`,
}))

export const CircleIcon = styledMUI(Box)(() => ({
  height: '24px',
  width: '24px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: `${theme.colors.midMedium}`,
  borderRadius: '50%',
}))

export const ContentCards = styledMUI(Box)(() => ({
  display: 'flex',
  width: '100%',
  gap: '8px',
}))

export const ContentParagraphs = styledMUI(List)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
}))

export const Paragraph = styledMUI(ListItem)(() => ({
  display: 'flex',
  alignItems: 'start',
  gap: '16px',
}))
