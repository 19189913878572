import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Moment } from '~/services/Trails/types'

import {
  AppBarComponent,
  CardDescription,
  ContainerComponent,
  DividerComponent,
  ModalBNCCComponent,
} from '~/components'
import CardDetailTrail from '~/components/Cards/CardDetailTrail/CardDetailTrail'
import { ModalClassesAvailableToSchedule } from '~/components/Modals/ModalClassesAvailableToSchedule/ModalClassesAvailableToSchedule'
import StageSteps from '~/components/Navigation/StageSteps/StageSteps'

import { TrailsHeaderComponent } from '~/containers/private/Fund2/Trails'

import { useTrailsGeneratedContext } from '~/pages/private/Fund1/TrailsGenerated/context'

import * as S from './styles'

export const TrailsGeneratedView = () => {
  const { trailId, classId } = useParams()
  const {
    openModalBNCC,
    handleCloseModalBNCC,
    handleOpenModalBNCC,
    handleOpenModalSchedule,
    isLoadingTrailsNotGenerated,
    dataClassDetails,
    dataClassInfos,
    isSameUser,
  } = useTrailsGeneratedContext()
  const [manualSuccess, setManualSuccess] = useState(false)
  const [trailIndex, setTrailIndex] = useState<number | null>(null)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [moments, setMoments] = useState<Moment[] | undefined>(undefined)
  const isPrevDisabled = currentIndex === 0

  const coStage = dataClassInfos?.class?.items[0]?.schoolGrade?.grade?.stage?.coStage
  const txClassShift = dataClassInfos?.class?.items[0]?.classShift?.txClassShift
  const lesson = dataClassDetails?.lesson
  const scheduleMoments = dataClassDetails?.moment

  const MappedCards = lesson?.map((lesson) => ({
    ...lesson,
    lessonTrackGroup: scheduleMoments?.find((group) => group.lessonTrackGroup.idLesson === lesson.idLesson),
  }))

  const mergedArray = MappedCards?.filter((item) => item.idLesson !== null).sort(
    (a: any, b: any) =>
      Number(a.lessonTrackGroup.lessonTrackGroup.nuOrder) - Number(b.lessonTrackGroup.lessonTrackGroup.nuOrder),
  )

  const itemsPerPage = mergedArray?.length <= 4 ? 4 : 3
  const totalCards = mergedArray?.length || 0
  const maxInitialIndex = totalCards - itemsPerPage
  const isNextDisabled = currentIndex >= totalCards - itemsPerPage
  const adjustedCurrentIndex = Math.min(currentIndex, maxInitialIndex)

  const closeToAvailableClasses = () => {
    setManualSuccess(false)
  }

  const handleActiveCard = (index: number) => {
    setTrailIndex(index)
  }

  function handleAllLessons() {
    setCurrentIndex(0)
  }

  const handleLeftClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 3)
    }
  }

  const handleRightClick = () => {
    if (moments && currentIndex < moments?.length - 3) {
      setCurrentIndex(currentIndex + 3)
    }
  }

  useEffect(() => {
    if (dataClassDetails) {
      setMoments(dataClassDetails?.moment)
    }
  }, [dataClassDetails])

  useEffect(() => {
    const actionAvailable = sessionStorage.getItem('@LEKTO:actionAvailableToSchedule') === 'true'
    setTimeout(() => {
      return setManualSuccess(actionAvailable)
    }, 800)
  }, [manualSuccess])

  return (
    <>
      <AppBarComponent />
      <ContainerComponent>
        <TrailsHeaderComponent
          isDisabledClasses={dataClassDetails?.class[0].inStatus}
          path={`/trail/${trailId}/occupied/${classId}`}
          txTitle={dataClassDetails?.txTitle}
          component={dataClassDetails?.component}
          professor={dataClassDetails?.professor}
          moment={dataClassDetails?.moment?.sort((a: any, b: any) => new Date(a.dtSchedule) - new Date(b.dtSchedule))}
          loading={isLoadingTrailsNotGenerated}
          lesson={dataClassDetails?.lesson}
          actionOpenModalBNCC={handleOpenModalBNCC}
          actionOpenModalSchedule={handleOpenModalSchedule}
          txSchoolGradeName={dataClassDetails?.class[0]?.txName}
          txClassShift={txClassShift}
          txGrade={dataClassDetails?.class[0]?.schoolGrade?.grade?.txGrade}
          coStage={coStage}
          isSameUser={isSameUser}
        />

        <DividerComponent />

        <S.ContainerLesson>
          <CardDescription description={dataClassDetails?.txDescription} />
          <StageSteps
            data={dataClassDetails}
            onChangeTrailIndex={trailIndex}
            handleAllLessons={handleAllLessons}
            handlePrevClick={handleLeftClick}
            handleNextClick={handleRightClick}
            isPrevDisabled={isPrevDisabled}
            isNextDisabled={isNextDisabled}
            adjustedCurrentIndex={adjustedCurrentIndex}
            currentIndex={currentIndex}
            itemsPerPage={itemsPerPage}
            isTrailGenerated={true}
          />
          <S.ContainerCardsList>
            {mergedArray?.slice(adjustedCurrentIndex, adjustedCurrentIndex + itemsPerPage).map((lesson, index) => (
              <CardDetailTrail
                key={index}
                index={index}
                dataTrail={lesson}
                handleActiveCard={() => handleActiveCard(lesson?.nuOrder - 1)}
                isTrailGenerated={true}
                idClass={Number(classId)}
                variant="P1"
                moment={lesson.moment}
                itemsPerPage={itemsPerPage}
              />
            ))}
          </S.ContainerCardsList>
        </S.ContainerLesson>

        <ModalBNCCComponent
          open={openModalBNCC}
          txGuidanceBNCC={dataClassDetails?.txGuidanceBNCC}
          actionCloseModal={handleCloseModalBNCC}
        />
        <DividerComponent />

        <ModalClassesAvailableToSchedule
          openAvailableClassesToScheduleModal={manualSuccess}
          closeToAvailableClasses={closeToAvailableClasses}
        />
      </ContainerComponent>
    </>
  )
}
