import { Backdrop, Box, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

export const Content = styledMUI(Box)(() => ({
  display: 'flex',
  padding: '16px 24px 24px 24px',
  flexDirection: 'column',
  gap: '16px',
  '@media (max-width: 600px)': {
    margin: '260px 24px 24px',
  },
}))

export const SkeletonBox = styledMUI(Box)(() => ({
  margin: '0px 24px 24px',
  borderRadius: '8px',
  '@media (max-width: 600px)': {
    margin: '260px 24px 24px',
  },
}))

export const CardSkeleton = styledMUI(Box)(() => ({
  minHeight: '150px',
  display: 'flex',
  borderRadius: '8px',
  gap: '8px',
  padding: '16px',
  justifyContent: 'space-between',
  backgroundColor: 'white',
  '@media (max-width: 600px)': {
    width: '100%',
  },
}))

export const ContentSkeleton = styledMUI(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  gap: '2%',
  '@media (max-width: 600px)': {
    width: '100%',
    display: 'grid',
    gap: '12px',
  },
}))
export const Loading = styledMUI(Backdrop)(() => ({}))

export const ContentAvaliar = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
}))

export const ListAccordions = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  marginTop: '30px',
}))

export const ContentAccordion = styledMUI(Box)(() => ({
  backgroundColor: '#F9FAFC',
  borderRadius: '8px',
  boxShadow: 'none',
}))

export const TextLabelAccordion = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '120%',
  color: '#0095FF',
}))

export const AssessmentContainer = styledMUI(Box)(() => ({
  padding: '0 16px',
}))

export const TextDescriptionAccordion = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '120%',
  color: '#0095FF',
}))

export const CircleIcon = styledMUI(Box)(() => ({
  height: '24px',
  width: '24px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#E2E5EC',
  borderRadius: '50%',
}))

export const ContentInfos = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: '24px',
  gap: '8px',
}))

export const ContentStepToStep = styledMUI(Box)(() => ({
  display: 'flex',
  padding: '8px',
  gap: '8px',
  backgroundColor: '#E2E5EC',
  borderRadius: '64px',
}))
