import { useNavigate, useParams } from 'react-router-dom'

import { UilBookReader, UilFavorite, UilNotes } from '@iconscout/react-unicons'
import { Tooltip } from '@mui/material'
import dayjs from 'dayjs'

import { useAuth } from '~/_context/Auth'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import {
  AppBarComponent,
  BackNavigationComponent,
  ButtonComponent,
  CardNavigationComponent,
  ContainerComponent,
  DividerComponent,
} from '~/components'
import { handleStatusStep, StepsTrackingChipComponent } from '~/components/ui/StepsTracking/StepsTracking'

import { HeaderLessonFullComponent } from '~/containers/private/Fund1/Lesson'

import { IStatus } from '~/pages/private/Fund1/Lesson/interfaces'

import { useLessonPageContext } from './context'
import * as S from './styles'

export const LessonView = () => {
  const { idLessonMoment } = useParams()
  const navigate = useNavigate()

  const { user } = useAuth()

  const { width } = useWindowDimensions()
  const isMobile = width && width < 768

  const { lesson, idLesson, lessonMomentDetails, lessonMomentsByTrack, nuOrder, totalClass, txGrade } =
    useLessonPageContext()

  const dtSchedule = lessonMomentDetails?.dtSchedule
  const txClassName = lessonMomentDetails?.class?.txName
  const txMomentStatus = lessonMomentDetails?.momentStatus.txMomentStatus
  const txTitle = lesson?.txTitle
  const status = lesson?.coMomentStatus as IStatus

  const isSameUser = user?.id_user === lesson?.professor.idUser

  const handleBncc = () => {
    navigate(`/offline/lesson-bncc-relations/${idLessonMoment}/lesson/${idLesson}`)
  }

  return (
    <>
      <AppBarComponent />

      {lesson && (
        <ContainerComponent>
          <S.ContentBack>
            <BackNavigationComponent path={'/offline/home'} />

            <S.Wrapper>
              {/* <PedagogicalPracticesComponent /> */}

              {isMobile ? (
                <Tooltip title="Integração com o currículo">
                  <S.ButtonIcon onClick={handleBncc}>
                    <UilBookReader size={16} color="#0095ff" />
                  </S.ButtonIcon>
                </Tooltip>
              ) : (
                <ButtonComponent
                  text="Integração com o currículo"
                  variant="outline"
                  size="small"
                  iconStart={<UilBookReader size="18" />}
                  fontWeight="normal"
                  onClick={handleBncc}
                />
              )}
            </S.Wrapper>
          </S.ContentBack>

          <>
            {lesson && (
              <HeaderLessonFullComponent
                isOffline
                projectId={lesson.idMoment}
                loading={false}
                classBefore={lessonMomentsByTrack ?? []}
                title={txTitle}
                nuLessonTrackGroups={totalClass}
                nuOrderLessonTrackGroup={nuOrder}
                nuOrderProjectTrackStages={nuOrder}
                statusCode={status}
                isSameLesson={true}
                statusText={txMomentStatus}
                coStage={lesson.class.schoolGrade.grade.coStage}
                dtSchedule={dtSchedule}
                avatas={lesson.student}
                isSameUser={isSameUser}
                isSameProfessor={isSameUser}
                professorImagePath={lesson.professor.txImagePath}
                professorName={lesson.professor.txName}
                dateHour={dayjs(dtSchedule).format('DD/MM/YYYY')}
                lessonYear={`${txGrade} - ${txClassName}`}
                isClassInStatus={lesson.class.inStatus}
              />
            )}
          </>
          <S.Content>
            <S.ContentDescription>
              {handleStatusStep(status) === 'Planning' ? (
                <StepsTrackingChipComponent
                  stepNumber={1}
                  text={'Planejamento e Ação'}
                  variant={'onStep'}
                  status={status}
                />
              ) : (
                <StepsTrackingChipComponent
                  stepNumber={1}
                  text={'Planejamento e Ação'}
                  variant={'checked'}
                  status={status}
                />
              )}
              <CardNavigationComponent
                label="Orientações da Aula"
                pathNavigation={`/offline/lesson/${idLessonMoment}/planning/${idLesson}`}
              />
            </S.ContentDescription>

            <DividerComponent />

            <S.ContentNavigation>
              {handleStatusStep(status) === 'Assessment' ? (
                <StepsTrackingChipComponent
                  stepNumber={2}
                  text={'Documentação e Avaliação'}
                  variant={'onStep'}
                  status={status}
                />
              ) : (
                <StepsTrackingChipComponent
                  stepNumber={2}
                  text={'Documentação e Avaliação'}
                  variant={
                    handleStatusStep(status) === 'Action' || handleStatusStep(status) === 'Planning'
                      ? 'disable'
                      : 'checked'
                  }
                  status={status}
                />
              )}
              <Tooltip title={'Para acessar as documentações você precisa sair do modo offline'} placement={'top'}>
                <span>
                  <CardNavigationComponent
                    icon={<UilNotes />}
                    label="Documentações"
                    disabled
                    pathNavigation={`/documentations/${idLessonMoment}/lesson/${idLesson}`}
                  />
                </span>
              </Tooltip>

              <Tooltip title={'Para acessar as avaliações você precisa sair do modo offline'} placement={'top'}>
                <span>
                  <CardNavigationComponent
                    icon={<UilFavorite />}
                    disabled
                    label="Avaliações"
                    pathNavigation={`/lesson-assessment/${idLessonMoment}/lesson/${idLesson}`}
                  />
                </span>
              </Tooltip>
            </S.ContentNavigation>
          </S.Content>
        </ContainerComponent>
      )}
    </>
  )
}
