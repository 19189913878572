import { useState } from 'react'

import { DropdownSkillComponent } from '~/components'

import * as S from './FixedEvidences.styles'

interface IFixedEvidenceProps {
  openIndex: number | null
  onToggle: (index: number) => void
  fixedEvidence: Array<{
    txAbility: string
    txEvidence: string
    txIconSkill: string
    txIconCompetency: string
    txCompetency: string
  }>
}

interface IFixedEvidenceComponentProps {
  fixedEvidence: Array<{
    txAbility: string
    txEvidence: string
    txIconSkill: string
    txIconCompetency: string
    txCompetency: string
  }>
}

const FixedEvidence = ({ fixedEvidence, openIndex, onToggle }: IFixedEvidenceProps) => {
  return (
    <S.ContainerEvidence>
      {fixedEvidence?.map((evidence, index) => (
        <DropdownSkillComponent
          key={index}
          isOpen={openIndex === index}
          onToggle={() => onToggle(index)}
          txSkillName={evidence.ability.txName}
          iconCompetency={evidence.ability.competence.txImagePath}
          iconSkill={evidence.ability.txImagePath}
          txCompetency={evidence.ability.competence.txName}
        />
      ))}
    </S.ContainerEvidence>
  )
}

export const FixedEvidencesComponent = ({ fixedEvidence }: IFixedEvidenceComponentProps) => {
  const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(null)

  const handleDropdownToggle = (index: number) => {
    setOpenDropdownIndex((prevIndex) => (prevIndex === index ? null : index))
  }

  return (
    <S.Container>
      <S.Title>ASETs da Aula</S.Title>
      <FixedEvidence openIndex={openDropdownIndex} onToggle={handleDropdownToggle} fixedEvidence={fixedEvidence} />
    </S.Container>
  )
}
