import { ProjectPlanningOfflinePageProvider } from './context'
import { ProjectPlanningViewOffline } from './view'

export const ProjectPlanningPageOffline = () => {
  return (
    <ProjectPlanningOfflinePageProvider>
      <ProjectPlanningViewOffline />
    </ProjectPlanningOfflinePageProvider>
  )
}
