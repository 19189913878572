import { Box, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'

import theme from '~/styles/theme'

export const Title = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '32px',
  lineHeight: '120%',
  color: theme.colors.actionPure,
}))

export const ContentBack = styled.div`
  gap: 16px;
  display: flex;
  justify-content: space-between;
`

export const HeaderContainer = styledMUI(Box)(() => ({
  width: 'inherit',
  position: 'fixed',
  zIndex: 100,
  marginBottom: '200px',
  backgroundColor: `${theme.colors.highPure}`,
  borderBottom: `1px solid ${theme.colors.midMedium}`,
  '@media (max-width: 600px)': {
    width: '100%',
    top: 0,
    left: 0,
  },
}))
export const ContainerTitle = styledMUI(Box)(() => ({
  padding: '24px',
  display: 'grid',
  gap: '16px',
}))

export const ContainerStep = styledMUI(Box)(() => ({
  borderTop: `1px solid ${theme.colors.midMedium}`,
  padding: '16px 24px',
}))
