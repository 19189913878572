import { Box, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  width: 100%;
  padding: 32px;
  position: relative;
  overflow-y: auto;

  .webcam-container:before {
    content: '';
    position: absolute;
    top: 57%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    z-index: 1;
  }

  @media screen and (max-width: 768px) {
    .webcam-container {
      width: 100px; /* Ajuste para telas menores */
    }
  }
`

export const UploadGalleryInput = styled.input`
  display: none;
`

export const ContentIcon = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '8px',
  width: '64px',
  height: '64px',
  background: '#E8F6FF',
  borderRadius: '64px',
}))

export const Title = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '120%',
  textAlign: 'center',
  color: '#000',
}))

export const Description = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '120%',
  textAlign: 'center',
  color: '#000',
}))

export const PreviewImage = styled.img`
  width: 250px;
  height: 250px;
  object-fit: cover;
  border-radius: 50%;
`
