import { createContext, useContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { useGetProjectInformation } from '~/services/Project'

import { IProjectPlanningPageContextProps, IProjectPlanningPageProviderProps } from './interface'

const ProjectPlanningPageContext = createContext<IProjectPlanningPageContextProps>({
  isLoading: false,
  projectMomentStage: undefined,
  guidance: '',
  txOrientation: '',
  txTitleLesson: '',
  fixedEvidence: {
    txAbility: '',
    txEvidence: '',
    txIconSkill: '',
    txIconCompetency: '',
    txCompetency: '',
  },
  titleNuOrder: 0,
})

const ProjectPlanningPageProvider = ({ children }: IProjectPlanningPageProviderProps) => {
  const { projectId } = useParams()

  const { data, isLoading } = useGetProjectInformation(Number(projectId))
  const status = data?.momentStatus?.coMomentStatus as string

  const fixedEvidence = data?.projectMomentStage[0]?.projectStage?.evidenceFixed

  const guidance = data?.projectTrackStage?.txDescription as string
  const projectMomentStage = data?.projectMomentStage

  const titleNuOrder = data?.projectTrackStage?.txTitle

  const txTitleLesson = data?.projectTrackStage?.txDescription as string
  const txOrientation = data?.projectTrackStage?.txGuidanceCode

  const lessonPlanningPageProviderValues = useMemo(() => {
    return {
      isLoading,
      fixedEvidence: fixedEvidence,
      guidance: guidance || '',
      projectMomentStage,
      txTitleLesson: txTitleLesson || '',
      titleNuOrder,
      txOrientation,
      status,
    }
    // eslint-disable-next-line
  }, [isLoading, guidance, projectMomentStage, fixedEvidence, titleNuOrder, txTitleLesson, txOrientation, status])

  return (
    <ProjectPlanningPageContext.Provider value={lessonPlanningPageProviderValues}>
      {children}
    </ProjectPlanningPageContext.Provider>
  )
}

const useProjectPlanningPageContext = () => useContext(ProjectPlanningPageContext)

export { ProjectPlanningPageProvider, useProjectPlanningPageContext }
