// Divider.styles.ts
import { Divider } from '@mui/material'
import { styled } from '@mui/material/styles'

import theme from '~/styles/theme'
export const ContainerDivider = styled(Divider)(({ color, className }) => ({
  backgroundColor: color || theme.colors.midMedium,
  ...(className?.includes('padded') && {
    margin: '24px 10px',
  }),
}))
