import { Accordion, AccordionDetails, AccordionSummary, Box, Card, List, ListItem, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

import theme from '~/styles/theme'

export const CardContainer = styledMUI(Card)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  boxShadow: 'none',

  animation: 'fadeIn 0.4s ease-out forwards',
  '@keyframes fadeIn': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
}))

export const TitleRow = styledMUI(Card)(() => ({
  display: 'flex',
  marginTop: '20px',
  flexDirection: 'row',
  gap: '8px',
  alignItems: 'center',
  boxShadow: 'none',
}))

export const NuOrder = styledMUI(Card)(() => ({
  borderRadius: '50%',
  width: '24px',
  height: '24px',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: `${theme.colors.midDark}`,
  color: `${theme.colors.highPure}`,
  fontSize: '12px',
}))

export const Title = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '150%',
  color: `${theme.colors.lowPure}`,
}))

export const AccordionContainer = styledMUI(Accordion)(() => ({
  backgroundColor: `${theme.colors.midLight}`,
  borderRadius: '8px',
  width: '100%',
  boxShadow: 'none',
  marginTop: '5px',

  '&.MuiAccordion-root:before': {
    display: 'none',
  },
}))

export const AccordionDetailsContent = styledMUI(AccordionDetails)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  width: '100%',
  padding: '0px 16px 16px',
  margin: '0px',
}))

export const AccordionSummaryContent = styledMUI(AccordionSummary)(() => ({
  padding: '0px 16px',
  margin: '0px',
  width: '100%',
}))

export const OrientationTitle = styledMUI(Typography)(() => ({
  color: `${theme.colors.lowDark}`,
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '120%',
}))

export const TextParagraph = styledMUI(Box)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '150%',
  color: `${theme.colors.lowPure}`,
}))

export const CircleIcon = styledMUI(Box)(() => ({
  height: '24px',
  width: '24px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: `${theme.colors.midMedium}`,
  borderRadius: '50%',
}))

export const ContentParagraphs = styledMUI(List)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
}))

export const Paragraph = styledMUI(ListItem)(() => ({
  display: 'flex',
  alignItems: 'start',
  gap: '16px',
}))
