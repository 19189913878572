import { Box, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'

import theme from '~/styles/theme'

export const CardSliderResumeContent = styled.div<{
  bgColor: string
  isTrailGenerated: boolean
  itemsPerPage: number
}>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  height: ${({ isTrailGenerated }) => (isTrailGenerated ? '250px' : '264px')};
  width: ${({ itemsPerPage }) => (itemsPerPage === 4 ? '207.2px' : '278px')};
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  justify-content: space-between;
  line-height: 100%;
  padding: 16px;
  color: ${({ theme }) => theme.colors.highPure};
  background-color: ${({ isTrailGenerated, bgColor, theme }) => (isTrailGenerated ? bgColor : theme.colors.midLight)};
  border-radius: 8px;
`
export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
export const ContentNumber = styled.div`
  display: flex;
  height: 24px;
  width: 24px;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.highPure};
  background-color: ${({ theme }) => theme.colors.midDark};
  z-index: 1;
`
export const StepNumber = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
`

export const ContainerTime = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const CardIndex = styled.div<{
  color: string
}>`
  min-width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.highPure};
  display: flex;
  border-radius: 24px;
  background-color: ${({ color }) => color};
`

export const CardTime = styled.div<{
  color: string
}>`
  color: ${({ color }) => color};
  font-size: 14px;
  background-color: ${({ theme }) => theme.colors.highPure};
  border-radius: 4px;
  padding: 4px 8px;
  font-weight: 700;
`

export const CardStatus = styled.div<{
  color: string
}>`
  color: ${({ color }) => color};
  display: flex;
  align-items: center;
  gap: 4px;

  p {
    font-size: 14px;
    line-height: 100%;
  }
`
export const TextSpan = styled.span``
export const TextIsSameUser = styled.span`
  color: ${({ theme }) => theme.colors.lowLight};

  /* Text/Button/Small Regular */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
`
export const TextProfessorInfo = styled.span`
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 14px */
  color: ${({ theme }) => theme.colors.midDark};
`

export const TitleCard = styledMUI(Typography)<{ type?: string }>(({ type }) => ({
  fontstyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '120%',
  display: '-webkit-box',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: type === 'P1' ? 2 : 3,
}))

export const ProfessorContent = styled.div`
  display: flex;
`
export const TitleLessonCard = styled.span<{ type?: string }>`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  min-width: fit-content;
  display: -webkit-box;
  -webkit-line-clamp: ${({ type }) => (type === 'P1' ? 2 : 3)};
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.lowPure};
`
export const ConteinerProfessor = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  border: `1px solid ${theme.colors.lowLight}`,
  color: `${theme.colors.lowPure}`,
  borderRadius: '4px',
  padding: '4px',
  height: '24px',
}))

export const TextInfoLabel = styledMUI(Typography)<{ isSameUser?: boolean; isThreeItemsPerPage?: boolean }>(
  ({ isSameUser, isThreeItemsPerPage }) => ({
    maxWidth:
      isSameUser && isThreeItemsPerPage
        ? '170px'
        : isSameUser && !isThreeItemsPerPage
        ? '95px'
        : !isSameUser && isThreeItemsPerPage
        ? '218px'
        : !isSameUser && !isThreeItemsPerPage
        ? '143px'
        : '100px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    WebkitLineClamp: 1,
    whiteSpace: 'nowrap',
    fontSize: '14px',
    color: `${theme.colors.lowPure}`,
    fontWeight: 700,
    alignItems: 'center',
    span: {
      color: `${theme.colors.lowLight}`,
      fontSize: '14px',
      fontWeight: 400,
    },
  }),
)

export const BtnExplorar = styled.button`
  display: flex;
  height: 24px;
  padding: 4px 8px;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 50px;
  width: 100%;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.actionPure};
  color: ${({ theme }) => theme.colors.actionPure};
  background-color: transparent;
  transition: background 0.3s, color 0.3s;
  &:hover {
    background: ${({ theme }) => theme.colors.actionPure};
    color: ${({ theme }) => theme.colors.highPure};
  }
`
