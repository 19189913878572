import { UilArrowDown } from '@iconscout/react-unicons'
import parse from 'html-react-parser'

import { CardDescription } from '~/components'

import { ICardInforProjectProps } from './CardInfo.interface'
import * as S from './CardInfo.styles'
export const CardInfoProject = ({ title, nuOrder, description, guidance }: ICardInforProjectProps) => {
  return (
    <S.CardContainer>
      <S.TitleRow>
        <S.NuOrder>{nuOrder}</S.NuOrder>
        <S.Title>{title}</S.Title>
      </S.TitleRow>
      <CardDescription description={description} />
      <S.AccordionContainer>
        <S.AccordionSummaryContent
          expandIcon={
            <S.CircleIcon>
              <UilArrowDown />
            </S.CircleIcon>
          }
        >
          <S.OrientationTitle>{'Orientações da Aula'}</S.OrientationTitle>
        </S.AccordionSummaryContent>

        <S.AccordionDetailsContent>
          <S.ContentParagraphs>
            <S.Paragraph>
              <S.TextParagraph>{parse(String(guidance))}</S.TextParagraph>
            </S.Paragraph>
          </S.ContentParagraphs>
        </S.AccordionDetailsContent>
      </S.AccordionContainer>
    </S.CardContainer>
  )
}
