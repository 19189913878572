import { AssessmentComponent } from '~/components'

import { useAssessmentPageContext } from '../context'

interface ITabFirstStep {
  idLessonMomentActivity: number
  txName: string
  evidenceDescription: string
  compentencyName: string
  idEvidence: string
  status: string
  txIconCompetency: string
  group: any
  txIconSkill: string
  lengthEvidence: number
  indexEvidence: number
  handleAssessmentBatch: any
  isClassInStatus: boolean
  handleAssessment(id: string, coAssessment: string, idEvidence: string): void
}

export const TabFirstStep = ({
  idLessonMomentActivity,
  handleAssessment,
  handleAssessmentBatch,
  txName,
  idEvidence,
  compentencyName,
  evidenceDescription,
  status,
  txIconCompetency,
  lengthEvidence,
  indexEvidence,
  txIconSkill,
  isClassInStatus,
  group,
}: ITabFirstStep) => {
  const { isSameUser } = useAssessmentPageContext()

  const isFinish = status === 'FINA'

  const filteredLessonMoment = {
    ...group,
    group: group?.group.filter((item: any) => item.idLessonMomentActivity === idLessonMomentActivity),
  }

  return (
    <>
      <AssessmentComponent
        handleDrop={handleAssessment}
        handleDropBatch={handleAssessmentBatch}
        txEvidenceName={txName}
        idEvidence={idEvidence}
        txEvidenceDescription={evidenceDescription}
        lengthEvidence={lengthEvidence}
        indexEvidence={indexEvidence}
        txCompentencyName={compentencyName}
        students={filteredLessonMoment}
        disabled={isFinish || !isSameUser || !isClassInStatus}
        txIconCompetency={txIconCompetency}
        txIconSkill={txIconSkill}
        idActivity={idLessonMomentActivity}
        type={'lesson'}
        skillType={'FIX'}
      />
    </>
  )
}
