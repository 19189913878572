import styled from 'styled-components'

export const Title = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
`

export const ContainerTitle = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
`
export const ContainerLesson = styled.div`
  padding: 24px 24px 16px 24px;
  display: grid;
  gap: 16px;
`

export const ContainerCardsList = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`
