import { useEffect, useState } from 'react'

import { UilArrowRight, UilArrowLeft } from '@iconscout/react-unicons'

import { SkeletonComponent } from '~/components/Basic/Skeleton/Skeleton'

import useIsMobile from '~/validations/isMobile'

import theme from '~/styles/theme'

import { ILessonStepsProps } from './StageSteps.interfaces'
import * as S from './StageSteps.styles'
export default function StageSteps({
  data,
  onChangeTrailIndex,
  handleAllLessons,
  handlePrevClick,
  handleNextClick,
  isPrevDisabled,
  hasLesson,
  isNextDisabled,
  adjustedCurrentIndex,
  currentIndex,
  itemsPerPage,
  isTrailGenerated,
  handleSelect,
}: ILessonStepsProps) {
  const valuableData = data?.lesson || data?.stage || []
  const isMobile = useIsMobile()
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    if (data) {
      setIsLoading(false)
    }
  }, [data])

  useEffect(() => {
    setActiveIndex(onChangeTrailIndex)
  }, [onChangeTrailIndex])

  return (
    <S.Container>
      {isLoading && (
        <>
          <SkeletonComponent width={170} height={40} />
          <SkeletonComponent width={170} height={40} />
          <SkeletonComponent width={170} height={40} />
          <SkeletonComponent width={170} height={40} />
          <SkeletonComponent width={170} height={40} />
        </>
      )}

      {!isLoading && valuableData && (
        <>
          {valuableData?.length >= 5 && (
            <S.BtnArrow data-testid="uil-arrow-left-btn" onClick={handlePrevClick} active={!isPrevDisabled}>
              <UilArrowLeft />
            </S.BtnArrow>
          )}
          <S.StepsContainer>
            <S.BtnAction data-testid="all-steps-btn" onClick={handleAllLessons} active={activeIndex === null}>
              {isMobile ? 'Todas' : data?.lesson ? 'Todas as Aulas' : 'Todas as Etapas'}
            </S.BtnAction>
            {valuableData?.map((_item, index: number) => (
              <S.StepsContent
                data-testid={`steps-btn-${index + 1}`}
                key={index}
                isViewTheme={valuableData?.length <= 4}
                isTrailGenerated={isTrailGenerated}
                isNull={activeIndex !== null}
                onClick={() => handleSelect(index)}
                active={
                  hasLesson
                    ? index === currentIndex - 1
                    : index >= adjustedCurrentIndex && index < adjustedCurrentIndex + itemsPerPage
                }
              >
                {`${index + 1}`}
              </S.StepsContent>
            ))}
          </S.StepsContainer>
          {valuableData?.length >= 5 && (
            <S.BtnArrow data-testid="uil-arrow-right-btn" onClick={handleNextClick} active={!isNextDisabled}>
              <UilArrowRight color={theme.colors.highPure} />
            </S.BtnArrow>
          )}
        </>
      )}
    </S.Container>
  )
}
