import { Alert, Box, Modal, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'

import theme from '~/styles/theme'

export const ContainerModal = styledMUI(Modal)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const ContentModal = styledMUI(Box)<{
  height: string | undefined
  width: string | undefined
  padding: string | undefined
}>(({ height, width, padding }) => ({
  height: height ? height : 'auto',
  width: width ? width : 'auto',
  overflowY: 'auto',
  overflowX: 'hidden',
  backgroundColor: '#fff',
  borderRadius: 16,
  display: 'flex',
  outline: 'none',
  padding: padding ? padding : '24px',
  '&::-webkit-scrollbar': {
    width: '5px',
  },

  '&::-webkit-scrollbar-thumb': {
    borderRadius: '8px',
    backgroundColor: '#CCCCCC',
    border: '1px solid #CCCCCC',
  },

  '&::-webkit-scrollbar-thumb:hover': {
    background: '#aaa',
    width: '4px',
  },
}))

export const ContentModalHeader = styledMUI(Box)(() => ({
  width: '80%',
  maxWidth: '920px',
  display: 'flex',
  height: '96vh',
  maxHeight: '676px',
  backgroundColor: theme.colors.highPure,
  borderRadius: '16px',
  flexDirection: 'column',
  position: 'relative',
  outline: 'none',
}))

export const ModalHeader = styledMUI(Box)(() => ({
  display: 'flex',
  padding: '16px',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

export const ModalBody = styledMUI(Box)(() => ({
  overflowY: 'auto',
  padding: '0px 24px 24px 24px',
}))

export const ModalFooter = styledMUI(Box)(() => ({
  padding: '16px 24px',
  borderBottomLeftRadius: '16px',
  borderBottomRightRadius: '16px',
  bottom: 0,
  backgroundColor: theme.colors.highPure,
  display: 'flex',
  gap: '16px',
}))

export const FooterContainer = styledMUI(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}))

export const WrapperFooterButtons = styledMUI(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginLeft: 'auto',
  gap: '16px',
}))

export const TextTitle = styledMUI(Typography)(() => ({
  fontWeight: 700,
  fontSize: '24px',
  lineHeight: '120%',
  color: theme.colors.actionPure,
}))

export const InfoAlert = styledMUI(Alert)(() => ({
  backgroundColor: '#E8F6FF',
  color: '#0095FF',
  '& .MuiAlert-icon': {
    color: '#0095FF',
  },
}))

export const WarnningAlert = styledMUI(Alert)(() => ({
  backgroundColor: '#FFF7DD',
  color: '#FF8A00',
  '& .MuiAlert-icon': {
    color: '#FF8A00',
  },
}))

export const SuccessAlert = styledMUI(Alert)(() => ({
  backgroundColor: '#E1FFEB',
  color: '#0CD04D',
  '& .MuiAlert-icon': {
    color: '#0CD04D',
  },
}))

export const WrapperHeaderButton = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`
