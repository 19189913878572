import { Backdrop, Box, IconButton, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'

import theme from '~/styles/theme'

export const ContainerScroll = styled.div`
  overflow-y: auto;
  height: 100vh;
`

export const ContentBack = styledMUI(Box, {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})<{ isMobile: boolean }>(({ isMobile }) => ({
  padding: '24px 24px 0',
  display: 'flex',
  flexDirection: isMobile ? 'column' : 'row',
  gap: isMobile ? '24px' : '0',
  justifyContent: 'space-between',
  backgroundColor: `${theme.colors.highPure}`,
}))

export const ContentHeader = styled.nav`
  position: sticky;
  top: 72px;
  z-index: 10;
  background-color: ${theme.colors.highPure};
  @media (max-width: 600px) {
    top: 0px;
  }
`
export const ContentBackSmall = styledMUI(Box)(() => ({
  padding: '24px 24px 0',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  backgroundColor: `${theme.colors.highPure}`,
}))

export const Title = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '120%',
  color: `${theme.colors.lowPure}`,
  maxWidth: '310px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}))

export const WrapperActionHeader = styled.div`
  display: flex;
  gap: 16px;
`

export const WrapperClass = styledMUI(Box)(() => ({
  borderRadius: '4px',
  border: `1px solid ${theme.colors.midDark}`,
  borderColor: `${theme.colors.midDark}`,
  color: `${theme.colors.midDark}`,
  fontSize: '14px',
  fontWeight: 400,
  display: 'flex',
  alignItems: 'center',
  padding: '4px 8px',
  gap: '8px',
  '@media (max-width: 600px)': {
    flexWrap: 'wrap',
  },
}))

export const ContainerTrackClass = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #7c8189;
  padding: 4px 6px;
  border-radius: 4px;

  span:last-child {
    border-right: none;
  }
`

export const ClassSpanInfo = styled.span`
  font-size: 14px;
  padding: 0 6px;
  color: #7c8189;
  border-right: 1px solid #e2e5ec;
  min-width: fit-content;

  &:last-child {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    min-width: auto;
    max-width: 100px;
  }
`

export const WrapperStudents = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  '@media (max-width: 600px)': {
    flexWrap: 'wrap',
  },
}))

export const ContentSkeleton = styled.div`
  padding: 24px 24px;
  gap: 10px;
`

export const Loading = styledMUI(Backdrop)(() => ({}))

export const Content = styled.div``
export const ContentStep = styled.div`
  display: flex;
  margin-bottom: 16px;
`

export const ContentDescription = styledMUI(Box, {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})<{ isMobile: boolean; isScrolling: boolean }>(({ isMobile, isScrolling }) => ({
  padding: '24px',
  marginTop: isScrolling && isMobile ? '90px' : '0',
}))

export const CardRef = styled.div``

export const ContentSteps = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 16px;
`

export const ContentNavigation = styled.ul`
  display: flex;
  padding: 24px;
  flex-direction: column;
  gap: 16px;
`

export const ButtonIcon = styledMUI(IconButton)(() => ({
  height: '32px',
  width: '32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50px',
  border: `1px solid ${theme.colors.actionPure}`,
  backgroundColor: `${theme.colors.highPure}`,
  padding: 0,
}))
