import { useRef } from 'react'

import { AppBarComponent, ContainerComponent } from '~/components'

import {
  FixedEvidencesComponent,
  HeaderPlanning,
  ProjectGeneralOrientation,
  VariableEvidencesComponent,
} from '~/containers/private/Fund2/Planning'

import { useProjectPlanningOfflinePageContext } from './context'
import * as S from './styles'

export const ProjectPlanningViewOffline = () => {
  const { fixedEvidences, txTitleLesson, projectMomentStage, txGuidance } = useProjectPlanningOfflinePageContext()

  const overallStepRef = useRef<HTMLDivElement>(null)

  return (
    <>
      <AppBarComponent />

      <ContainerComponent>
        <>
          <HeaderPlanning isOffline={true} txNameLesson={txTitleLesson} />
          <S.Container>
            <S.FixedEvidencesContainer>
              <FixedEvidencesComponent fixedEvidence={fixedEvidences} />
            </S.FixedEvidencesContainer>

            {projectMomentStage?.[0]?.projectStage.evidenceVariable && (
              <S.VariableEvidencesContainer>
                <VariableEvidencesComponent projectMomentStage={projectMomentStage} />
              </S.VariableEvidencesContainer>
            )}

            <ProjectGeneralOrientation title={txGuidance} accordionRef={overallStepRef} key={'GeneralOrientation'} />
          </S.Container>
        </>
      </ContainerComponent>
    </>
  )
}
