import { QuickNavigationComponent } from '~/components'

import { IHeaderComponentProps } from './Header.interfaces'
import * as S from './Header.styles'

export const HeaderComponent = ({ title, statusBlock }: IHeaderComponentProps) => {
  return (
    <S.HeaderContainer>
      <QuickNavigationComponent stepActived="assessment" label={title} statusBlock={statusBlock} />

      <S.Title variant="h1">Avaliação</S.Title>

      <S.TextLabel variant="caption">Consulte e realize todas as avaliações da aula aqui.</S.TextLabel>
    </S.HeaderContainer>
  )
}
