import { Box, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

import theme from '~/styles/theme'

export const ModalClassesAvailableContainer = styledMUI(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '32px',
  textAlign: 'center',
  gap: '24px',
})

export const ModalTitle = styledMUI('strong')({
  fontSize: '20px',
})

export const TitleContainer = styledMUI(Box)({
  display: 'flex',
  padding: '8px',
  alignItems: 'center',
  gap: '16px',
  flex: '1 0 0',
  borderRadius: '8px',
  backgroundColor: theme.colors.primaryLight,
})

export const TitleTrail = styledMUI(Typography)({
  color: theme.colors.lowPure,
  fontSize: '16px',
  fontWeight: '700',
  lineHeight: '120%',
  textAlign: 'start',
})

export const InfoTrail = styledMUI(Typography)({
  color: theme.colors.lowPure,
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '120%',
})

export const ClassesCardContainer = styledMUI(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '16px',
  borderRadius: '8px',
  backgroundColor: theme.colors.midLight,
  width: '100%',
})
export const ClassesTitleSection = styledMUI(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  gap: '8px',

  '& span': {
    fontSize: '20px',
  },
})

export const ContentContainer = styledMUI(Box)({
  overflowY: 'auto',
  gap: '16px',
  display: 'flex',
  flexDirection: 'column',
})
