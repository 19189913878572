import { Typography } from '@mui/material'
import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  justify-content: left;
  align-items: flex-start;
`
export const ContainerEvidence = styled.div`
  display: grid;
  gap: 8px;
`

export const TitleEvidence = styled(Typography)`
  color: ${({ theme }) => theme.colors.quaternaryPure};
  font-size: 16px;
  font-weight: 700 !important;

  span {
    font-weight: 500;
  }
`

export const Title = styled(Typography)`
  font-weight: 700 !important;
  font-size: 16px;
  line-height: 120%;
  color: #000;
  margin-bottom: 8px;

  span {
    color: ${({ theme }) => theme.colors.quaternaryPure};
  }
`
