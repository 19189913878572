import { useNavigate, useParams } from 'react-router-dom'

import { UilBookReader, UilFavorite, UilNotes } from '@iconscout/react-unicons'
import { Tooltip } from '@mui/material'
import dayjs from 'dayjs'

import { useAuth } from '~/_context/Auth'

import {
  AppBarComponent,
  BackNavigationComponent,
  ButtonComponent,
  CardNavigationComponent,
  ContainerComponent,
  DividerComponent,
} from '~/components'
import { StepsTrackingChipComponent } from '~/components/ui/StepsTracking/StepsTracking'

import { HeaderLessonFullComponent } from '~/containers/private/Fund2/Project'

import useIsMobile from '~/validations/isMobile'
import { renderStatusText } from '~/validations/renderStatusText'

import { useProjectPageContext } from './context'
import * as S from './styles'

export const ProjectView = () => {
  const {
    project,
    projectStage,
    txGrade,
    txClassName,
    classBefore,
    dtSchedule,
    titleNuOrder,
    nuProjectTrackStages,
    nuOrder,
  } = useProjectPageContext()

  const { projectId } = useParams()
  const navigate = useNavigate()

  const { user } = useAuth()

  const isMobile = useIsMobile()

  const isSameUser = user?.id_user === project?.professor.idUser

  const titleByNuOrder = (nuOrder: number) => {
    switch (nuOrder) {
      case 1:
        return 'Contextualização e Chuva de Ideias'
      case 2:
        return 'Ideação e Planejamento'
      case 3:
        return 'Execução do Planejamento'
      case 4:
        return 'Partilha e Escuta Coletiva'
    }
  }

  const handleBncc = () => {
    navigate(`/offline/project-bncc-relations/${projectId}`)
  }
  return (
    <>
      <AppBarComponent />
      <ContainerComponent>
        <S.ContentBack>
          <BackNavigationComponent path={'/offline/home'} />
          <S.WrapperBncc>
            {isMobile ? (
              <Tooltip title="Integração com o currículo">
                <S.ButtonIcon onClick={handleBncc}>
                  <UilBookReader size={16} color="#0095ff" />
                </S.ButtonIcon>
              </Tooltip>
            ) : (
              <ButtonComponent
                text="Integração com o currículo"
                variant="outline"
                size="small"
                iconStart={<UilBookReader size="18" />}
                fontWeight="normal"
                onClick={handleBncc}
              />
            )}
          </S.WrapperBncc>
        </S.ContentBack>
        <HeaderLessonFullComponent
          isOffline
          loading={false}
          loadingStudents={false}
          nuOrderProjectTrackStages={nuOrder}
          nuLessonTrackGroups={nuProjectTrackStages}
          loadingChangeStatus={false}
          nuOrderLessonTrackGroup={nuOrder}
          isSameUser={isSameUser}
          isSameProfessor={isSameUser}
          professorImagePath={project?.professor.txImagePath}
          professorName={project?.professor.txName}
          classBefore={classBefore || []}
          projectId={projectId as unknown as number}
          dtSchedule={dtSchedule}
          title={titleByNuOrder(titleNuOrder)}
          statusCode={project?.coMomentStatus as string}
          statusText={renderStatusText(project?.coMomentStatus as string)}
          actionStatus={() => false}
          component={
            projectStage?.[0]?.projectStage?.project?.projectComponent?.find(
              (component) => component.coComponentType === 'PRI',
            )?.component.txComponent
          }
          actionManageStudents={() => false}
          dateHour={dayjs(dtSchedule).format('DD/MM/YYYY')}
          lessonYear={`${txGrade} - ${txClassName}`}
          avatas={project?.student}
          isMobile={isMobile}
          isSameProject={true}
        />

        <S.Content>
          <S.ContentPlanning>
            <StepsTrackingChipComponent
              stepNumber={1}
              text={'Planejamento e Ação'}
              variant={'checked'}
              status={status}
              isOffline
            />
            <CardNavigationComponent
              label="Orientações da Aula"
              pathNavigation={`/offline/project/${projectId}/planning`}
            />
          </S.ContentPlanning>

          <DividerComponent />

          <S.ContentNavigation>
            <StepsTrackingChipComponent
              stepNumber={2}
              isOffline
              text={'Documentação e Avaliação'}
              variant={'disable'}
              status={status}
            />
            <Tooltip title={'Para acessar as documentações você precisa sair do modo offline'} placement={'top'}>
              <span>
                <CardNavigationComponent
                  icon={<UilNotes />}
                  label="Documentações"
                  disabled
                  pathNavigation={`/project-documentations/`}
                />
              </span>
            </Tooltip>

            <Tooltip title={'Para acessar as avaliações você precisa sair do modo offline'} placement={'top'}>
              <span>
                <CardNavigationComponent
                  icon={<UilFavorite />}
                  disabled
                  label="Avaliações"
                  pathNavigation={`/project-assessment/`}
                />
              </span>
            </Tooltip>
          </S.ContentNavigation>
        </S.Content>
      </ContainerComponent>
    </>
  )
}
