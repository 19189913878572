import { useState } from 'react'

import { useRegisterSW } from 'virtual:pwa-register/react'

import { ButtonComponent, ModalComponent } from '~/components'

import logo from '../../../assets/images/logo_purple.png'
import * as S from './ReloadPrompt.styles'

function ReloadPrompt() {
  const offlineMode = localStorage.getItem('@LEKTO:offline') == 'true'
  const [showAlertModal, setShowAlertModal] = useState(true)

  const {
    needRefresh: [needRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    async onRegisteredSW(swUrl, swr) {
      // eslint-disable-next-line no-console
      console.log('SW Registered!')

      swr &&
        setInterval(async () => {
          if (!(!swr.installing && navigator)) return
          if ('connection' in navigator && !navigator.onLine) return
          if (offlineMode) return

          try {
            const resp = await fetch(swUrl, {
              cache: 'no-store',
              headers: {
                cache: 'no-store',
                'cache-control': 'no-cache',
              },
            })

            if (resp?.ok) await swr.update()
          } catch (error) {
            /* empty */
          }
        }, 60000)
    },
    async onRegisterError(error) {
      // eslint-disable-next-line no-console
      console.log('SW registration error', error)

      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        const cacheKeyNames = await caches.keys()

        for (const name of cacheKeyNames) {
          await caches.delete(name)
        }
      }
    },
  })

  const handleRefresh = async () => {
    try {
      await updateServiceWorker(true)
      if ('caches' in window) {
        const cacheNames = await caches.keys()
        await Promise.all(cacheNames.map((cache) => caches.delete(cache)))
      }
      window.location.reload()
    } catch (error) {
      /*  */
    }
  }

  const handleCloseModal = () => {
    setShowAlertModal(false)
  }

  return (
    <>
      {needRefresh && (
        <ModalComponent open={showAlertModal} actionCloseModal={handleCloseModal} maxWidth="448px">
          <S.Container>
            <S.Logo src={logo} height={51} width={103} alt="Logo" />
            <h2>Uma nova versão da Plataforma Lekto está disponível!</h2>
            <p>Aproveite as melhorias que tornaram a plataforma mais rápida, segura e confiável!</p>
            <ButtonComponent size="large" variant="solid" text="Atualizar" onClick={handleRefresh} />
          </S.Container>
        </ModalComponent>
      )}
    </>
  )
}

export default ReloadPrompt
