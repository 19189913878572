import { useState } from 'react'

import { DropdownSkillComponent } from '~/components'

import * as S from './FixedEvidences.styles'

interface IFixedEvidenceProps {
  isOpen: boolean
  onToggle: () => void
  fixedSkill: {
    evidence: {
      txName: string
      ability: {
        txName: string
        txImagePath: string
        competence: {
          txName: string
          txImagePath: string
        }
      }
    }
  }
}

interface IFixedEvidenceComponentProps {
  steps: any
}

const FixedEvidence = ({ fixedSkill, isOpen, onToggle }: IFixedEvidenceProps) => {
  return (
    <S.ContainerEvidence>
      <DropdownSkillComponent
        isOpen={isOpen}
        onToggle={onToggle}
        txSkillName={fixedSkill?.evidence.ability?.txName}
        iconCompetency={fixedSkill?.evidence.ability?.competence?.txImagePath}
        iconSkill={fixedSkill?.evidence.ability?.txImagePath}
        txSkillDescription={fixedSkill?.evidence.txName}
        txBncc=""
        txCompetency={fixedSkill?.evidence.ability?.competence?.txName}
      />
    </S.ContainerEvidence>
  )
}

export const FixedEvidencesComponent = ({ steps }: IFixedEvidenceComponentProps) => {
  const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(null)

  const handleDropdownToggle = (index: number) => {
    setOpenDropdownIndex(index === openDropdownIndex ? null : index)
  }
  return (
    <S.Container>
      <S.Title>ASETs da aula</S.Title>
      {steps?.map((item: any, index: number) => (
        <FixedEvidence
          isOpen={index === openDropdownIndex}
          onToggle={() => handleDropdownToggle(index)}
          key={item.idLessonStep}
          fixedSkill={item.lessonActivityEvidence}
        />
      ))}
    </S.Container>
  )
}
