import { Link } from 'react-router-dom'

import styled, { css } from 'styled-components'

import theme from '~/styles/theme'

import { IQuickNavigationProps } from './QuickNavigation.interfaces'

export const Container = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 600px) {
    gap: 8px;
    display: grid;
    justify-content: start;
  }
`

export const Wrapper = styled.nav`
  display: flex;
  align-items: center;
  z-index: 100;
  gap: 8px;
`

export const TextLabel = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: ${theme.colors.lowPure};
`

export const ButtonNavigation = styled(Link)<IQuickNavigationProps>`
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  text-decoration: none;

  ${({ variant, active }) =>
    !variant &&
    !active &&
    css`
      border: 1px solid ${theme.colors.actionPure};
      color: ${theme.colors.actionPure};
    `}

  ${({ variant, active }) =>
    !variant &&
    active &&
    css`
      border: 1px solid ${theme.colors.actionPure};
      background: ${theme.colors.actionPure};
      color: ${theme.colors.highPure};
    `}
`
export const ButtonAssessment = styled(Link)<IQuickNavigationProps>`
  cursor: default;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  text-decoration: none;
  border: 1px solid ${theme.colors.lowLight};
`
