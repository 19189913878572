import { createContext, useContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { useOfflineMode } from '~/_context/Offline'

import { IProjectPlanningOfflinePageContextProps, IProjectPlanningOfflinePageProviderProps } from './interface'

const ProjectPlanningPageContextOffline = createContext<IProjectPlanningOfflinePageContextProps>({
  txTitleLesson: '',
  fixedEvidences: undefined,
  projectMomentStage: undefined,
  txGuidance: '',
})

const ProjectPlanningOfflinePageProvider = ({ children }: IProjectPlanningOfflinePageProviderProps) => {
  const { projectId } = useParams()

  const { getOfflineInformation } = useOfflineMode()

  const fixedEvidences = getOfflineInformation?.[0].projectMomentsDetails
    ?.filter((item) => item.idProjectMoment === Number(projectId))
    .map((item, index) => {
      return {
        txAbility: item.projectMomentStage[index]?.projectStage?.evidenceFixed[index]?.ability?.txName,
        txEvidence: item.projectMomentStage[index]?.projectStage?.evidenceFixed[index]?.txName,
        txCompetency: item.projectMomentStage[index]?.projectStage?.evidenceFixed[index]?.ability?.competence?.txName,
        txIconSkill: item.projectMomentStage[index]?.projectStage?.evidenceFixed[index]?.ability?.txImagePath,
        txIconCompetency:
          item.projectMomentStage[index]?.projectStage?.evidenceFixed[index]?.ability?.competence?.txImagePath,
      }
    })

  const itensVariables = getOfflineInformation?.[0].projectMomentsDetails?.filter(
    (item) => item.idProjectMoment === Number(projectId),
  )

  const projectMomentStage = itensVariables?.[0].projectMomentStage

  const txTitleLesson =
    getOfflineInformation?.[0]?.moments.find((step) => step.idMoment === Number(projectId))?.txTitle || ''

  const itemOrder = getOfflineInformation?.[0].projectMomentsDetails?.filter(
    (item) => item.idProjectMoment === Number(projectId),
  )[0].nuOrderProjectTrackStages

  const txGuidance = getOfflineInformation?.[0].projectTracks?.[0].projectTrackStage?.filter(
    (item) => item.nuOrder === Number(itemOrder),
  )[0].txGuidanceCode

  const projectPlanningOfflinePageProviderValues = useMemo(() => {
    return {
      fixedEvidences,
      txTitleLesson,
      projectMomentStage,
      txGuidance,
    }
  }, [fixedEvidences, txTitleLesson, projectMomentStage, txGuidance])

  return (
    <ProjectPlanningPageContextOffline.Provider value={projectPlanningOfflinePageProviderValues}>
      {children}
    </ProjectPlanningPageContextOffline.Provider>
  )
}

const useProjectPlanningOfflinePageContext = () => useContext(ProjectPlanningPageContextOffline)

export { ProjectPlanningOfflinePageProvider, useProjectPlanningOfflinePageContext }
