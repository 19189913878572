import React from 'react'

import { AccordionDetails, Box, Divider } from '@mui/material'

import { AssessmentComponent } from '~/components'

import * as S from '../styles'

interface ProjectFormProps {
  tabActived: number
  idClass: number
  handleActiveNewTab: () => object
  status: string
  stages: any[]
  isSameUser: boolean
  idProject: number
  isLoading: boolean
  isClassInStatus: boolean
  mutateAssessment: () => object
  mutateAssessmentBatch: () => object
  students: any[]
}

const ProjectForm: React.FC<ProjectFormProps> = ({
  isSameUser,
  status,
  idProject,
  mutateAssessment,
  mutateAssessmentBatch,
  students,
  isLoading,
  idClass,
  isClassInStatus,
  stages,
}) => {
  const isFinish = status === 'FINA'

  const handleAssessment = (id: string, coAssessment: string, idEvidence: string) => {
    mutateAssessment({ projectId: String(idProject), idClass: idClass }, [
      {
        idMomentGroup: id,
        coAssessment,
        idEvidence,
      },
    ])
  }

  const handleAssessmentBatch = (body) => {
    body.projectId = String(idProject)
    body.idClass = String(idClass)
    mutateAssessmentBatch(body)
  }

  const totalEvidences = stages.reduce((total, stage) => {
    return total + (stage?.projectStage?.evidenceFixed?.length || 0)
  }, 0)

  let globalItemIndex = 0

  return (
    <>
      {isLoading && (
        <Box mt={25} p={2} display="flex" flexDirection="column" gap={2}>
          {/* Skeletons aqui */}
        </Box>
      )}
      {!isLoading &&
        stages.map((stage, stageIndex) => {
          const fixedPayload = stage?.projectStage?.evidenceFixed || []

          return (
            <S.Content key={stageIndex} sx={stageIndex === 0 ? { marginTop: '170px' } : { marginTop: '0px' }}>
              <S.ContentAccordion>
                <AccordionDetails sx={{ marginTop: '-10px' }}>
                  {fixedPayload.map((payloadItem, evidenceIndex) => {
                    globalItemIndex += 1

                    return (
                      <AssessmentComponent
                        key={payloadItem?.idEvidence || evidenceIndex}
                        disabled={isFinish || !isSameUser || !isClassInStatus}
                        handleDrop={handleAssessment}
                        handleDropBatch={handleAssessmentBatch}
                        txEvidenceName={payloadItem?.ability?.txName}
                        txEvidenceDescription={payloadItem?.txName}
                        indexEvidence={globalItemIndex}
                        lengthEvidence={totalEvidences}
                        txCompentencyName={payloadItem?.ability?.competence?.txName}
                        txIconSkill={payloadItem?.ability?.txImagePath}
                        txIconCompetency={payloadItem?.ability?.competence?.txImagePath}
                        students={{
                          ...students,
                          group: students?.group?.filter(
                            (item: any) => item.idProjectMomentStage === stage.idProjectMomentStage,
                          ),
                        }}
                        idEvidence={payloadItem?.idEvidence}
                        skillType={'FIX'}
                        idClass={idClass}
                        type={'project'}
                        idActivity={stage.idProjectMomentStage}
                      />
                    )
                  })}
                </AccordionDetails>
              </S.ContentAccordion>
              <Divider sx={{ marginTop: '16px' }} />
            </S.Content>
          )
        })}
    </>
  )
}

export default ProjectForm
