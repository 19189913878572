/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { useGetTrailById } from '~/services/Trails'

import {
  ITrailsGeneratedEMPageContextProps,
  ITrailsGeneratedEMPageProviderProps,
} from '~/pages/private/TrailsGenerated/interfaces'

const TrailsGeneratedEMPageContext = createContext<ITrailsGeneratedEMPageContextProps>({
  dataClassDetails: undefined,
  removeClassDetails: () => {},
  isLoadingTrailsDetails: false,
  refetchDataClass: () => {},
})

const TrailsGeneratedEMPageProvider = ({ children }: ITrailsGeneratedEMPageProviderProps) => {
  const { trailId, classId } = useParams()
  const {
    data: dataClassDetails,
    remove: removeClassDetails,
    isLoading: isLoadingTrailsDetails,
    refetch: refetchDataClass,
  } = useGetTrailById(Number(classId), Number(trailId))

  const TrailsGeneratedEMPageProviderValues = useMemo(() => {
    return {
      dataClassDetails,
      removeClassDetails,
      isLoadingTrailsDetails,
      refetchDataClass,
    }
  }, [dataClassDetails, removeClassDetails, isLoadingTrailsDetails, refetchDataClass])

  return (
    <TrailsGeneratedEMPageContext.Provider value={TrailsGeneratedEMPageProviderValues}>
      {children}
    </TrailsGeneratedEMPageContext.Provider>
  )
}

const useTrailsGeneratedEMPageContext = () => useContext(TrailsGeneratedEMPageContext)

export { TrailsGeneratedEMPageProvider, useTrailsGeneratedEMPageContext }
